nav {
  margin-top: 0em;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 8rem;
  z-index: 100;
  position: fixed;
  width: 100%;
  background: #0d0d0d;
}
.link {
  text-decoration: none;
  display: flex;
  color: white;
  align-items: center;
  padding: 0 2rem;
  height: 100%;
  width: 8%;
  cursor: pointer;
  font-size: 16px;
  font-family: 'poppins';
}
.mobile-menu-icon {
  display: none;
}
.icons {
  display: flex;
  align-items: center;
}
.icon-tabler {
  margin-right: 5px;
}
.github-icon {
  color: white;
}
.menu-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
 
.shicon{
  font-size: 1.5em;
  margin-top: -0.3em;
  color: linear-gradient(103deg, rgb(180, 178, 178) 0%, #711E48 100%);


}
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .beat-animation {
    animation: heartbeat 1s infinite;
    background-color: #711E48;
    width: 1.5em;
    height: 1.5em;
    padding: 5px;
    border-radius: 5px;
   
    
  }

  @media screen and (max-width: 768px) {
    .nav {
      padding: 0.5rem; /* Adjust padding for smaller screens */
    }
  
    .menu-items {
      display: none; /* Hide menu items on smaller screens */
    }
  
    .mobile-menu-icon {
      display: flex;
      color: white;
      align-items: center;
      font-size: 4vh;
      cursor: pointer;
      top: 0;
      right: 0;
      padding: 1rem;
      position: absolute;
    }
  
    .github-corner {
      display: none; /* Hide GitHub corner on smaller screens */
    }
  }
