html, body {
  height: 100%;
}

body {
  background: black;
  background-image: radial-gradient(circle at center, #303030 0%, #181818 10%, black 60%);
  overflow-x: hidden;
}

.wrap {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 800px;
          perspective: 800px;
          height: 60em;
          overflow: hidden;
}

.tri {
  height: 0;
  width: 0;
  position: absolute;
  top: 0%;
  left: 50%;
}

.tri:nth-child(1) {
  border-top: 19px solid #1500ff;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(325deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(325deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim1 10s infinite linear;
          animation: anim1 10s infinite linear;
  -webkit-animation-delay: -0.05s;
          animation-delay: -0.05s;
  opacity: 0;
}

@-webkit-keyframes anim1 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(487.5deg) translate3d(130px, 300px, 1000px) scale(1);
            transform: rotate(487.5deg) translate3d(130px, 300px, 1000px) scale(1);
  }
}

@keyframes anim1 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(487.5deg) translate3d(130px, 300px, 1000px) scale(1);
            transform: rotate(487.5deg) translate3d(130px, 300px, 1000px) scale(1);
  }
}

.tri:nth-child(2) {
  border-top: 19px solid #ff008c;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(106deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(106deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim2 10s infinite linear;
          animation: anim2 10s infinite linear;
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  opacity: 0;
}

@-webkit-keyframes anim2 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(159deg) translate3d(472px, 234px, 1000px) scale(1);
            transform: rotate(159deg) translate3d(472px, 234px, 1000px) scale(1);
  }
}

@keyframes anim2 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(159deg) translate3d(472px, 234px, 1000px) scale(1);
            transform: rotate(159deg) translate3d(472px, 234px, 1000px) scale(1);
  }
}

.tri:nth-child(3) {
  border-top: 17px solid cyan;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
  margin-left: -8.5px;
  margin-top: -8.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(5deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(5deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim3 10s infinite linear;
          animation: anim3 10s infinite linear;
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
  opacity: 0;
}

@-webkit-keyframes anim3 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(7.5deg) translate3d(971px, 963px, 1000px) scale(1);
            transform: rotate(7.5deg) translate3d(971px, 963px, 1000px) scale(1);
  }
}

@keyframes anim3 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(7.5deg) translate3d(971px, 963px, 1000px) scale(1);
            transform: rotate(7.5deg) translate3d(971px, 963px, 1000px) scale(1);
  }
}

.tri:nth-child(4) {
  border-top: 21px solid #ff3300;
  border-right: 21px solid transparent;
  border-left: 21px solid transparent;
  margin-left: -10.5px;
  margin-top: -10.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(14deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(14deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim4 10s infinite linear;
          animation: anim4 10s infinite linear;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  opacity: 0;
}

@-webkit-keyframes anim4 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(21deg) translate3d(778px, 693px, 1000px) scale(1);
            transform: rotate(21deg) translate3d(778px, 693px, 1000px) scale(1);
  }
}

@keyframes anim4 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(21deg) translate3d(778px, 693px, 1000px) scale(1);
            transform: rotate(21deg) translate3d(778px, 693px, 1000px) scale(1);
  }
}

.tri:nth-child(5) {
  border-top: 41px solid #ff00ae;
  border-right: 41px solid transparent;
  border-left: 41px solid transparent;
  margin-left: -20.5px;
  margin-top: -20.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(248deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(248deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim5 10s infinite linear;
          animation: anim5 10s infinite linear;
  -webkit-animation-delay: -0.25s;
          animation-delay: -0.25s;
  opacity: 0;
}

@-webkit-keyframes anim5 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(372deg) translate3d(483px, 399px, 1000px) scale(1);
            transform: rotate(372deg) translate3d(483px, 399px, 1000px) scale(1);
  }
}

@keyframes anim5 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(372deg) translate3d(483px, 399px, 1000px) scale(1);
            transform: rotate(372deg) translate3d(483px, 399px, 1000px) scale(1);
  }
}

.tri:nth-child(6) {
  border-top: 10px solid #b300ff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  margin-left: -5px;
  margin-top: -5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(344deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(344deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim6 10s infinite linear;
          animation: anim6 10s infinite linear;
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  opacity: 0;
}

@-webkit-keyframes anim6 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(516deg) translate3d(829px, 387px, 1000px) scale(1);
            transform: rotate(516deg) translate3d(829px, 387px, 1000px) scale(1);
  }
}

@keyframes anim6 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(516deg) translate3d(829px, 387px, 1000px) scale(1);
            transform: rotate(516deg) translate3d(829px, 387px, 1000px) scale(1);
  }
}

.tri:nth-child(7) {
  border-top: 38px solid #d900ff;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(143deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(143deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim7 10s infinite linear;
          animation: anim7 10s infinite linear;
  -webkit-animation-delay: -0.35s;
          animation-delay: -0.35s;
  opacity: 0;
}

@-webkit-keyframes anim7 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(214.5deg) translate3d(499px, 290px, 1000px) scale(1);
            transform: rotate(214.5deg) translate3d(499px, 290px, 1000px) scale(1);
  }
}

@keyframes anim7 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(214.5deg) translate3d(499px, 290px, 1000px) scale(1);
            transform: rotate(214.5deg) translate3d(499px, 290px, 1000px) scale(1);
  }
}

.tri:nth-child(8) {
  border-top: 47px solid #ff3700;
  border-right: 47px solid transparent;
  border-left: 47px solid transparent;
  margin-left: -23.5px;
  margin-top: -23.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(169deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(169deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim8 10s infinite linear;
          animation: anim8 10s infinite linear;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  opacity: 0;
}

@-webkit-keyframes anim8 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(253.5deg) translate3d(780px, 926px, 1000px) scale(1);
            transform: rotate(253.5deg) translate3d(780px, 926px, 1000px) scale(1);
  }
}

@keyframes anim8 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(253.5deg) translate3d(780px, 926px, 1000px) scale(1);
            transform: rotate(253.5deg) translate3d(780px, 926px, 1000px) scale(1);
  }
}

.tri:nth-child(9) {
  border-top: 20px solid #a6ff00;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(104deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(104deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim9 10s infinite linear;
          animation: anim9 10s infinite linear;
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
  opacity: 0;
}

@-webkit-keyframes anim9 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(156deg) translate3d(664px, 71px, 1000px) scale(1);
            transform: rotate(156deg) translate3d(664px, 71px, 1000px) scale(1);
  }
}

@keyframes anim9 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(156deg) translate3d(664px, 71px, 1000px) scale(1);
            transform: rotate(156deg) translate3d(664px, 71px, 1000px) scale(1);
  }
}

.tri:nth-child(10) {
  border-top: 43px solid #00ff7b;
  border-right: 43px solid transparent;
  border-left: 43px solid transparent;
  margin-left: -21.5px;
  margin-top: -21.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(67deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(67deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim10 10s infinite linear;
          animation: anim10 10s infinite linear;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  opacity: 0;
}

@-webkit-keyframes anim10 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(100.5deg) translate3d(513px, 137px, 1000px) scale(1);
            transform: rotate(100.5deg) translate3d(513px, 137px, 1000px) scale(1);
  }
}

@keyframes anim10 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(100.5deg) translate3d(513px, 137px, 1000px) scale(1);
            transform: rotate(100.5deg) translate3d(513px, 137px, 1000px) scale(1);
  }
}

.tri:nth-child(11) {
  border-top: 38px solid #8400ff;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(182deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(182deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim11 10s infinite linear;
          animation: anim11 10s infinite linear;
  -webkit-animation-delay: -0.55s;
          animation-delay: -0.55s;
  opacity: 0;
}

@-webkit-keyframes anim11 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(273deg) translate3d(700px, 81px, 1000px) scale(1);
            transform: rotate(273deg) translate3d(700px, 81px, 1000px) scale(1);
  }
}

@keyframes anim11 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(273deg) translate3d(700px, 81px, 1000px) scale(1);
            transform: rotate(273deg) translate3d(700px, 81px, 1000px) scale(1);
  }
}

.tri:nth-child(12) {
  border-top: 39px solid #ff6600;
  border-right: 39px solid transparent;
  border-left: 39px solid transparent;
  margin-left: -19.5px;
  margin-top: -19.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(59deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(59deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim12 10s infinite linear;
          animation: anim12 10s infinite linear;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  opacity: 0;
}

@-webkit-keyframes anim12 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(88.5deg) translate3d(404px, 592px, 1000px) scale(1);
            transform: rotate(88.5deg) translate3d(404px, 592px, 1000px) scale(1);
  }
}

@keyframes anim12 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(88.5deg) translate3d(404px, 592px, 1000px) scale(1);
            transform: rotate(88.5deg) translate3d(404px, 592px, 1000px) scale(1);
  }
}

.tri:nth-child(13) {
  border-top: 1px solid #09ff00;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-left: -0.5px;
  margin-top: -0.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(349deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(349deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim13 10s infinite linear;
          animation: anim13 10s infinite linear;
  -webkit-animation-delay: -0.65s;
          animation-delay: -0.65s;
  opacity: 0;
}

@-webkit-keyframes anim13 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(523.5deg) translate3d(67px, 626px, 1000px) scale(1);
            transform: rotate(523.5deg) translate3d(67px, 626px, 1000px) scale(1);
  }
}

@keyframes anim13 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(523.5deg) translate3d(67px, 626px, 1000px) scale(1);
            transform: rotate(523.5deg) translate3d(67px, 626px, 1000px) scale(1);
  }
}

.tri:nth-child(14) {
  border-top: 4px solid #00ff2b;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(3);
  -webkit-transform: rotate(181deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(181deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim14 10s infinite linear;
          animation: anim14 10s infinite linear;
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  opacity: 0;
}

@-webkit-keyframes anim14 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(271.5deg) translate3d(786px, 737px, 1000px) scale(1);
            transform: rotate(271.5deg) translate3d(786px, 737px, 1000px) scale(1);
  }
}

@keyframes anim14 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(271.5deg) translate3d(786px, 737px, 1000px) scale(1);
            transform: rotate(271.5deg) translate3d(786px, 737px, 1000px) scale(1);
  }
}

.tri:nth-child(15) {
  border-top: 18px solid #ff0400;
  border-right: 18px solid transparent;
  border-left: 18px solid transparent;
  margin-left: -9px;
  margin-top: -9px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(91deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(91deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim15 10s infinite linear;
          animation: anim15 10s infinite linear;
  -webkit-animation-delay: -0.75s;
          animation-delay: -0.75s;
  opacity: 0;
}

@-webkit-keyframes anim15 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(136.5deg) translate3d(470px, 453px, 1000px) scale(1);
            transform: rotate(136.5deg) translate3d(470px, 453px, 1000px) scale(1);
  }
}

@keyframes anim15 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(136.5deg) translate3d(470px, 453px, 1000px) scale(1);
            transform: rotate(136.5deg) translate3d(470px, 453px, 1000px) scale(1);
  }
}

.tri:nth-child(16) {
  border-top: 49px solid #ff005e;
  border-right: 49px solid transparent;
  border-left: 49px solid transparent;
  margin-left: -24.5px;
  margin-top: -24.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(6deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(6deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim16 10s infinite linear;
          animation: anim16 10s infinite linear;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  opacity: 0;
}

@-webkit-keyframes anim16 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(9deg) translate3d(788px, 71px, 1000px) scale(1);
            transform: rotate(9deg) translate3d(788px, 71px, 1000px) scale(1);
  }
}

@keyframes anim16 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(9deg) translate3d(788px, 71px, 1000px) scale(1);
            transform: rotate(9deg) translate3d(788px, 71px, 1000px) scale(1);
  }
}

.tri:nth-child(17) {
  border-top: 24px solid #00bbff;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  margin-left: -12px;
  margin-top: -12px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(51deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(51deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim17 10s infinite linear;
          animation: anim17 10s infinite linear;
  -webkit-animation-delay: -0.85s;
          animation-delay: -0.85s;
  opacity: 0;
}

@-webkit-keyframes anim17 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(76.5deg) translate3d(366px, 114px, 1000px) scale(1);
            transform: rotate(76.5deg) translate3d(366px, 114px, 1000px) scale(1);
  }
}

@keyframes anim17 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(76.5deg) translate3d(366px, 114px, 1000px) scale(1);
            transform: rotate(76.5deg) translate3d(366px, 114px, 1000px) scale(1);
  }
}

.tri:nth-child(18) {
  border-top: 28px solid #00ff09;
  border-right: 28px solid transparent;
  border-left: 28px solid transparent;
  margin-left: -14px;
  margin-top: -14px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(333deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(333deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim18 10s infinite linear;
          animation: anim18 10s infinite linear;
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  opacity: 0;
}

@-webkit-keyframes anim18 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(499.5deg) translate3d(730px, 201px, 1000px) scale(1);
            transform: rotate(499.5deg) translate3d(730px, 201px, 1000px) scale(1);
  }
}

@keyframes anim18 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(499.5deg) translate3d(730px, 201px, 1000px) scale(1);
            transform: rotate(499.5deg) translate3d(730px, 201px, 1000px) scale(1);
  }
}

.tri:nth-child(19) {
  border-top: 39px solid #4800ff;
  border-right: 39px solid transparent;
  border-left: 39px solid transparent;
  margin-left: -19.5px;
  margin-top: -19.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(105deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(105deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim19 10s infinite linear;
          animation: anim19 10s infinite linear;
  -webkit-animation-delay: -0.95s;
          animation-delay: -0.95s;
  opacity: 0;
}

@-webkit-keyframes anim19 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(157.5deg) translate3d(179px, 849px, 1000px) scale(1);
            transform: rotate(157.5deg) translate3d(179px, 849px, 1000px) scale(1);
  }
}

@keyframes anim19 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(157.5deg) translate3d(179px, 849px, 1000px) scale(1);
            transform: rotate(157.5deg) translate3d(179px, 849px, 1000px) scale(1);
  }
}

.tri:nth-child(20) {
  border-top: 41px solid #95ff00;
  border-right: 41px solid transparent;
  border-left: 41px solid transparent;
  margin-left: -20.5px;
  margin-top: -20.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(228deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(228deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim20 10s infinite linear;
          animation: anim20 10s infinite linear;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  opacity: 0;
}

@-webkit-keyframes anim20 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(342deg) translate3d(516px, 933px, 1000px) scale(1);
            transform: rotate(342deg) translate3d(516px, 933px, 1000px) scale(1);
  }
}

@keyframes anim20 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(342deg) translate3d(516px, 933px, 1000px) scale(1);
            transform: rotate(342deg) translate3d(516px, 933px, 1000px) scale(1);
  }
}

.tri:nth-child(21) {
  border-top: 13px solid #ff001a;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
  margin-left: -6.5px;
  margin-top: -6.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(204deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(204deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim21 10s infinite linear;
          animation: anim21 10s infinite linear;
  -webkit-animation-delay: -1.05s;
          animation-delay: -1.05s;
  opacity: 0;
}

@-webkit-keyframes anim21 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(306deg) translate3d(722px, 734px, 1000px) scale(1);
            transform: rotate(306deg) translate3d(722px, 734px, 1000px) scale(1);
  }
}

@keyframes anim21 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(306deg) translate3d(722px, 734px, 1000px) scale(1);
            transform: rotate(306deg) translate3d(722px, 734px, 1000px) scale(1);
  }
}

.tri:nth-child(22) {
  border-top: 15px solid #00ff73;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  margin-left: -7.5px;
  margin-top: -7.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(321deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(321deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim22 10s infinite linear;
          animation: anim22 10s infinite linear;
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  opacity: 0;
}

@-webkit-keyframes anim22 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(481.5deg) translate3d(645px, 83px, 1000px) scale(1);
            transform: rotate(481.5deg) translate3d(645px, 83px, 1000px) scale(1);
  }
}

@keyframes anim22 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(481.5deg) translate3d(645px, 83px, 1000px) scale(1);
            transform: rotate(481.5deg) translate3d(645px, 83px, 1000px) scale(1);
  }
}

.tri:nth-child(23) {
  border-top: 15px solid #ffb700;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  margin-left: -7.5px;
  margin-top: -7.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(240deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(240deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim23 10s infinite linear;
          animation: anim23 10s infinite linear;
  -webkit-animation-delay: -1.15s;
          animation-delay: -1.15s;
  opacity: 0;
}

@-webkit-keyframes anim23 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(360deg) translate3d(393px, 325px, 1000px) scale(1);
            transform: rotate(360deg) translate3d(393px, 325px, 1000px) scale(1);
  }
}

@keyframes anim23 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(360deg) translate3d(393px, 325px, 1000px) scale(1);
            transform: rotate(360deg) translate3d(393px, 325px, 1000px) scale(1);
  }
}

.tri:nth-child(24) {
  border-top: 36px solid #00ffb7;
  border-right: 36px solid transparent;
  border-left: 36px solid transparent;
  margin-left: -18px;
  margin-top: -18px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(320deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(320deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim24 10s infinite linear;
          animation: anim24 10s infinite linear;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  opacity: 0;
}

@-webkit-keyframes anim24 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(480deg) translate3d(685px, 636px, 1000px) scale(1);
            transform: rotate(480deg) translate3d(685px, 636px, 1000px) scale(1);
  }
}

@keyframes anim24 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(480deg) translate3d(685px, 636px, 1000px) scale(1);
            transform: rotate(480deg) translate3d(685px, 636px, 1000px) scale(1);
  }
}

.tri:nth-child(25) {
  border-top: 48px solid #dd00ff;
  border-right: 48px solid transparent;
  border-left: 48px solid transparent;
  margin-left: -24px;
  margin-top: -24px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(251deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(251deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim25 10s infinite linear;
          animation: anim25 10s infinite linear;
  -webkit-animation-delay: -1.25s;
          animation-delay: -1.25s;
  opacity: 0;
}

@-webkit-keyframes anim25 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(376.5deg) translate3d(89px, 498px, 1000px) scale(1);
            transform: rotate(376.5deg) translate3d(89px, 498px, 1000px) scale(1);
  }
}

@keyframes anim25 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(376.5deg) translate3d(89px, 498px, 1000px) scale(1);
            transform: rotate(376.5deg) translate3d(89px, 498px, 1000px) scale(1);
  }
}

.tri:nth-child(26) {
  border-top: 17px solid #ffbb00;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
  margin-left: -8.5px;
  margin-top: -8.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(127deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(127deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim26 10s infinite linear;
          animation: anim26 10s infinite linear;
  -webkit-animation-delay: -1.3s;
          animation-delay: -1.3s;
  opacity: 0;
}

@-webkit-keyframes anim26 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(190.5deg) translate3d(400px, 453px, 1000px) scale(1);
            transform: rotate(190.5deg) translate3d(400px, 453px, 1000px) scale(1);
  }
}

@keyframes anim26 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(190.5deg) translate3d(400px, 453px, 1000px) scale(1);
            transform: rotate(190.5deg) translate3d(400px, 453px, 1000px) scale(1);
  }
}

.tri:nth-child(27) {
  border-top: 38px solid #ff00bb;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(66deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(66deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim27 10s infinite linear;
          animation: anim27 10s infinite linear;
  -webkit-animation-delay: -1.35s;
          animation-delay: -1.35s;
  opacity: 0;
}

@-webkit-keyframes anim27 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(99deg) translate3d(29px, 459px, 1000px) scale(1);
            transform: rotate(99deg) translate3d(29px, 459px, 1000px) scale(1);
  }
}

@keyframes anim27 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(99deg) translate3d(29px, 459px, 1000px) scale(1);
            transform: rotate(99deg) translate3d(29px, 459px, 1000px) scale(1);
  }
}

.tri:nth-child(28) {
  border-top: 19px solid #ff00cc;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(40deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(40deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim28 10s infinite linear;
          animation: anim28 10s infinite linear;
  -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
  opacity: 0;
}

@-webkit-keyframes anim28 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(60deg) translate3d(623px, 652px, 1000px) scale(1);
            transform: rotate(60deg) translate3d(623px, 652px, 1000px) scale(1);
  }
}

@keyframes anim28 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(60deg) translate3d(623px, 652px, 1000px) scale(1);
            transform: rotate(60deg) translate3d(623px, 652px, 1000px) scale(1);
  }
}

.tri:nth-child(29) {
  border-top: 6px solid #55ff00;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(203deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(203deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim29 10s infinite linear;
          animation: anim29 10s infinite linear;
  -webkit-animation-delay: -1.45s;
          animation-delay: -1.45s;
  opacity: 0;
}

@-webkit-keyframes anim29 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(304.5deg) translate3d(717px, 167px, 1000px) scale(1);
            transform: rotate(304.5deg) translate3d(717px, 167px, 1000px) scale(1);
  }
}

@keyframes anim29 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(304.5deg) translate3d(717px, 167px, 1000px) scale(1);
            transform: rotate(304.5deg) translate3d(717px, 167px, 1000px) scale(1);
  }
}

.tri:nth-child(30) {
  border-top: 1px solid #00ffa2;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-left: -0.5px;
  margin-top: -0.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(321deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(321deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim30 10s infinite linear;
          animation: anim30 10s infinite linear;
  -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s;
  opacity: 0;
}

@-webkit-keyframes anim30 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(481.5deg) translate3d(787px, 6px, 1000px) scale(1);
            transform: rotate(481.5deg) translate3d(787px, 6px, 1000px) scale(1);
  }
}

@keyframes anim30 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(481.5deg) translate3d(787px, 6px, 1000px) scale(1);
            transform: rotate(481.5deg) translate3d(787px, 6px, 1000px) scale(1);
  }
}

.tri:nth-child(31) {
  border-top: 9px solid #ffc800;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(277deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(277deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim31 10s infinite linear;
          animation: anim31 10s infinite linear;
  -webkit-animation-delay: -1.55s;
          animation-delay: -1.55s;
  opacity: 0;
}

@-webkit-keyframes anim31 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(415.5deg) translate3d(308px, 513px, 1000px) scale(1);
            transform: rotate(415.5deg) translate3d(308px, 513px, 1000px) scale(1);
  }
}

@keyframes anim31 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(415.5deg) translate3d(308px, 513px, 1000px) scale(1);
            transform: rotate(415.5deg) translate3d(308px, 513px, 1000px) scale(1);
  }
}

.tri:nth-child(32) {
  border-top: 36px solid #ff002f;
  border-right: 36px solid transparent;
  border-left: 36px solid transparent;
  margin-left: -18px;
  margin-top: -18px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(301deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(301deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim32 10s infinite linear;
          animation: anim32 10s infinite linear;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  opacity: 0;
}

@-webkit-keyframes anim32 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(451.5deg) translate3d(783px, 494px, 1000px) scale(1);
            transform: rotate(451.5deg) translate3d(783px, 494px, 1000px) scale(1);
  }
}

@keyframes anim32 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(451.5deg) translate3d(783px, 494px, 1000px) scale(1);
            transform: rotate(451.5deg) translate3d(783px, 494px, 1000px) scale(1);
  }
}

.tri:nth-child(33) {
  border-top: 19px solid #f2ff00;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(240deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(240deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim33 10s infinite linear;
          animation: anim33 10s infinite linear;
  -webkit-animation-delay: -1.65s;
          animation-delay: -1.65s;
  opacity: 0;
}

@-webkit-keyframes anim33 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(360deg) translate3d(843px, 995px, 1000px) scale(1);
            transform: rotate(360deg) translate3d(843px, 995px, 1000px) scale(1);
  }
}

@keyframes anim33 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(360deg) translate3d(843px, 995px, 1000px) scale(1);
            transform: rotate(360deg) translate3d(843px, 995px, 1000px) scale(1);
  }
}

.tri:nth-child(34) {
  border-top: 46px solid #b700ff;
  border-right: 46px solid transparent;
  border-left: 46px solid transparent;
  margin-left: -23px;
  margin-top: -23px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(105deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(105deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim34 10s infinite linear;
          animation: anim34 10s infinite linear;
  -webkit-animation-delay: -1.7s;
          animation-delay: -1.7s;
  opacity: 0;
}

@-webkit-keyframes anim34 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(157.5deg) translate3d(701px, 906px, 1000px) scale(1);
            transform: rotate(157.5deg) translate3d(701px, 906px, 1000px) scale(1);
  }
}

@keyframes anim34 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(157.5deg) translate3d(701px, 906px, 1000px) scale(1);
            transform: rotate(157.5deg) translate3d(701px, 906px, 1000px) scale(1);
  }
}

.tri:nth-child(35) {
  border-top: 30px solid #00ff51;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  margin-left: -15px;
  margin-top: -15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(126deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(126deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim35 10s infinite linear;
          animation: anim35 10s infinite linear;
  -webkit-animation-delay: -1.75s;
          animation-delay: -1.75s;
  opacity: 0;
}

@-webkit-keyframes anim35 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(189deg) translate3d(358px, 847px, 1000px) scale(1);
            transform: rotate(189deg) translate3d(358px, 847px, 1000px) scale(1);
  }
}

@keyframes anim35 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(189deg) translate3d(358px, 847px, 1000px) scale(1);
            transform: rotate(189deg) translate3d(358px, 847px, 1000px) scale(1);
  }
}

.tri:nth-child(36) {
  border-top: 10px solid #a600ff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  margin-left: -5px;
  margin-top: -5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(298deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(298deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim36 10s infinite linear;
          animation: anim36 10s infinite linear;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
  opacity: 0;
}

@-webkit-keyframes anim36 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(447deg) translate3d(929px, 995px, 1000px) scale(1);
            transform: rotate(447deg) translate3d(929px, 995px, 1000px) scale(1);
  }
}

@keyframes anim36 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(447deg) translate3d(929px, 995px, 1000px) scale(1);
            transform: rotate(447deg) translate3d(929px, 995px, 1000px) scale(1);
  }
}

.tri:nth-child(37) {
  border-top: 48px solid #00ffaa;
  border-right: 48px solid transparent;
  border-left: 48px solid transparent;
  margin-left: -24px;
  margin-top: -24px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(314deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(314deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim37 10s infinite linear;
          animation: anim37 10s infinite linear;
  -webkit-animation-delay: -1.85s;
          animation-delay: -1.85s;
  opacity: 0;
}

@-webkit-keyframes anim37 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(471deg) translate3d(287px, 206px, 1000px) scale(1);
            transform: rotate(471deg) translate3d(287px, 206px, 1000px) scale(1);
  }
}

@keyframes anim37 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(471deg) translate3d(287px, 206px, 1000px) scale(1);
            transform: rotate(471deg) translate3d(287px, 206px, 1000px) scale(1);
  }
}

.tri:nth-child(38) {
  border-top: 27px solid #fffb00;
  border-right: 27px solid transparent;
  border-left: 27px solid transparent;
  margin-left: -13.5px;
  margin-top: -13.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(261deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(261deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim38 10s infinite linear;
          animation: anim38 10s infinite linear;
  -webkit-animation-delay: -1.9s;
          animation-delay: -1.9s;
  opacity: 0;
}

@-webkit-keyframes anim38 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(391.5deg) translate3d(335px, 943px, 1000px) scale(1);
            transform: rotate(391.5deg) translate3d(335px, 943px, 1000px) scale(1);
  }
}

@keyframes anim38 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(391.5deg) translate3d(335px, 943px, 1000px) scale(1);
            transform: rotate(391.5deg) translate3d(335px, 943px, 1000px) scale(1);
  }
}

.tri:nth-child(39) {
  border-top: 19px solid #33ff00;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(251deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(251deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim39 10s infinite linear;
          animation: anim39 10s infinite linear;
  -webkit-animation-delay: -1.95s;
          animation-delay: -1.95s;
  opacity: 0;
}

@-webkit-keyframes anim39 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(376.5deg) translate3d(880px, 493px, 1000px) scale(1);
            transform: rotate(376.5deg) translate3d(880px, 493px, 1000px) scale(1);
  }
}

@keyframes anim39 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(376.5deg) translate3d(880px, 493px, 1000px) scale(1);
            transform: rotate(376.5deg) translate3d(880px, 493px, 1000px) scale(1);
  }
}

.tri:nth-child(40) {
  border-top: 30px solid #8400ff;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  margin-left: -15px;
  margin-top: -15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(239deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(239deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim40 10s infinite linear;
          animation: anim40 10s infinite linear;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  opacity: 0;
}

@-webkit-keyframes anim40 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(358.5deg) translate3d(671px, 524px, 1000px) scale(1);
            transform: rotate(358.5deg) translate3d(671px, 524px, 1000px) scale(1);
  }
}

@keyframes anim40 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(358.5deg) translate3d(671px, 524px, 1000px) scale(1);
            transform: rotate(358.5deg) translate3d(671px, 524px, 1000px) scale(1);
  }
}

.tri:nth-child(41) {
  border-top: 26px solid #ff0048;
  border-right: 26px solid transparent;
  border-left: 26px solid transparent;
  margin-left: -13px;
  margin-top: -13px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(255deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(255deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim41 10s infinite linear;
          animation: anim41 10s infinite linear;
  -webkit-animation-delay: -2.05s;
          animation-delay: -2.05s;
  opacity: 0;
}

@-webkit-keyframes anim41 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(382.5deg) translate3d(273px, 128px, 1000px) scale(1);
            transform: rotate(382.5deg) translate3d(273px, 128px, 1000px) scale(1);
  }
}

@keyframes anim41 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(382.5deg) translate3d(273px, 128px, 1000px) scale(1);
            transform: rotate(382.5deg) translate3d(273px, 128px, 1000px) scale(1);
  }
}

.tri:nth-child(42) {
  border-top: 44px solid #e1ff00;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(212deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(212deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim42 10s infinite linear;
          animation: anim42 10s infinite linear;
  -webkit-animation-delay: -2.1s;
          animation-delay: -2.1s;
  opacity: 0;
}

@-webkit-keyframes anim42 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(318deg) translate3d(667px, 913px, 1000px) scale(1);
            transform: rotate(318deg) translate3d(667px, 913px, 1000px) scale(1);
  }
}

@keyframes anim42 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(318deg) translate3d(667px, 913px, 1000px) scale(1);
            transform: rotate(318deg) translate3d(667px, 913px, 1000px) scale(1);
  }
}

.tri:nth-child(43) {
  border-top: 44px solid #eeff00;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(264deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(264deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim43 10s infinite linear;
          animation: anim43 10s infinite linear;
  -webkit-animation-delay: -2.15s;
          animation-delay: -2.15s;
  opacity: 0;
}

@-webkit-keyframes anim43 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(396deg) translate3d(867px, 790px, 1000px) scale(1);
            transform: rotate(396deg) translate3d(867px, 790px, 1000px) scale(1);
  }
}

@keyframes anim43 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(396deg) translate3d(867px, 790px, 1000px) scale(1);
            transform: rotate(396deg) translate3d(867px, 790px, 1000px) scale(1);
  }
}

.tri:nth-child(44) {
  border-top: 18px solid #04ff00;
  border-right: 18px solid transparent;
  border-left: 18px solid transparent;
  margin-left: -9px;
  margin-top: -9px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(229deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(229deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim44 10s infinite linear;
          animation: anim44 10s infinite linear;
  -webkit-animation-delay: -2.2s;
          animation-delay: -2.2s;
  opacity: 0;
}

@-webkit-keyframes anim44 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(343.5deg) translate3d(785px, 762px, 1000px) scale(1);
            transform: rotate(343.5deg) translate3d(785px, 762px, 1000px) scale(1);
  }
}

@keyframes anim44 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(343.5deg) translate3d(785px, 762px, 1000px) scale(1);
            transform: rotate(343.5deg) translate3d(785px, 762px, 1000px) scale(1);
  }
}

.tri:nth-child(45) {
  border-top: 17px solid #0048ff;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
  margin-left: -8.5px;
  margin-top: -8.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(118deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(118deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim45 10s infinite linear;
          animation: anim45 10s infinite linear;
  -webkit-animation-delay: -2.25s;
          animation-delay: -2.25s;
  opacity: 0;
}

@-webkit-keyframes anim45 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(177deg) translate3d(34px, 820px, 1000px) scale(1);
            transform: rotate(177deg) translate3d(34px, 820px, 1000px) scale(1);
  }
}

@keyframes anim45 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(177deg) translate3d(34px, 820px, 1000px) scale(1);
            transform: rotate(177deg) translate3d(34px, 820px, 1000px) scale(1);
  }
}

.tri:nth-child(46) {
  border-top: 9px solid #0900ff;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(56deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(56deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim46 10s infinite linear;
          animation: anim46 10s infinite linear;
  -webkit-animation-delay: -2.3s;
          animation-delay: -2.3s;
  opacity: 0;
}

@-webkit-keyframes anim46 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(84deg) translate3d(9px, 470px, 1000px) scale(1);
            transform: rotate(84deg) translate3d(9px, 470px, 1000px) scale(1);
  }
}

@keyframes anim46 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(84deg) translate3d(9px, 470px, 1000px) scale(1);
            transform: rotate(84deg) translate3d(9px, 470px, 1000px) scale(1);
  }
}

.tri:nth-child(47) {
  border-top: 8px solid #ff002b;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  margin-left: -4px;
  margin-top: -4px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(170deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(170deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim47 10s infinite linear;
          animation: anim47 10s infinite linear;
  -webkit-animation-delay: -2.35s;
          animation-delay: -2.35s;
  opacity: 0;
}

@-webkit-keyframes anim47 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(255deg) translate3d(941px, 358px, 1000px) scale(1);
            transform: rotate(255deg) translate3d(941px, 358px, 1000px) scale(1);
  }
}

@keyframes anim47 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(255deg) translate3d(941px, 358px, 1000px) scale(1);
            transform: rotate(255deg) translate3d(941px, 358px, 1000px) scale(1);
  }
}

.tri:nth-child(48) {
  border-top: 2px solid #ff0095;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  margin-left: -1px;
  margin-top: -1px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(158deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(158deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim48 10s infinite linear;
          animation: anim48 10s infinite linear;
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
  opacity: 0;
}

@-webkit-keyframes anim48 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(237deg) translate3d(292px, 172px, 1000px) scale(1);
            transform: rotate(237deg) translate3d(292px, 172px, 1000px) scale(1);
  }
}

@keyframes anim48 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(237deg) translate3d(292px, 172px, 1000px) scale(1);
            transform: rotate(237deg) translate3d(292px, 172px, 1000px) scale(1);
  }
}

.tri:nth-child(49) {
  border-top: 25px solid #00ff11;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
  margin-left: -12.5px;
  margin-top: -12.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(166deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(166deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim49 10s infinite linear;
          animation: anim49 10s infinite linear;
  -webkit-animation-delay: -2.45s;
          animation-delay: -2.45s;
  opacity: 0;
}

@-webkit-keyframes anim49 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(249deg) translate3d(650px, 364px, 1000px) scale(1);
            transform: rotate(249deg) translate3d(650px, 364px, 1000px) scale(1);
  }
}

@keyframes anim49 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(249deg) translate3d(650px, 364px, 1000px) scale(1);
            transform: rotate(249deg) translate3d(650px, 364px, 1000px) scale(1);
  }
}

.tri:nth-child(50) {
  border-top: 29px solid #f200ff;
  border-right: 29px solid transparent;
  border-left: 29px solid transparent;
  margin-left: -14.5px;
  margin-top: -14.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(77deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(77deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim50 10s infinite linear;
          animation: anim50 10s infinite linear;
  -webkit-animation-delay: -2.5s;
          animation-delay: -2.5s;
  opacity: 0;
}

@-webkit-keyframes anim50 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(115.5deg) translate3d(999px, 563px, 1000px) scale(1);
            transform: rotate(115.5deg) translate3d(999px, 563px, 1000px) scale(1);
  }
}

@keyframes anim50 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(115.5deg) translate3d(999px, 563px, 1000px) scale(1);
            transform: rotate(115.5deg) translate3d(999px, 563px, 1000px) scale(1);
  }
}

.tri:nth-child(51) {
  border-top: 16px solid #ccff00;
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  margin-left: -8px;
  margin-top: -8px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(40deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(40deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim51 10s infinite linear;
          animation: anim51 10s infinite linear;
  -webkit-animation-delay: -2.55s;
          animation-delay: -2.55s;
  opacity: 0;
}

@-webkit-keyframes anim51 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(60deg) translate3d(268px, 861px, 1000px) scale(1);
            transform: rotate(60deg) translate3d(268px, 861px, 1000px) scale(1);
  }
}

@keyframes anim51 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(60deg) translate3d(268px, 861px, 1000px) scale(1);
            transform: rotate(60deg) translate3d(268px, 861px, 1000px) scale(1);
  }
}

.tri:nth-child(52) {
  border-top: 16px solid #00ff40;
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  margin-left: -8px;
  margin-top: -8px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(98deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(98deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim52 10s infinite linear;
          animation: anim52 10s infinite linear;
  -webkit-animation-delay: -2.6s;
          animation-delay: -2.6s;
  opacity: 0;
}

@-webkit-keyframes anim52 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(147deg) translate3d(607px, 458px, 1000px) scale(1);
            transform: rotate(147deg) translate3d(607px, 458px, 1000px) scale(1);
  }
}

@keyframes anim52 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(147deg) translate3d(607px, 458px, 1000px) scale(1);
            transform: rotate(147deg) translate3d(607px, 458px, 1000px) scale(1);
  }
}

.tri:nth-child(53) {
  border-top: 45px solid #ff00b7;
  border-right: 45px solid transparent;
  border-left: 45px solid transparent;
  margin-left: -22.5px;
  margin-top: -22.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(287deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(287deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim53 10s infinite linear;
          animation: anim53 10s infinite linear;
  -webkit-animation-delay: -2.65s;
          animation-delay: -2.65s;
  opacity: 0;
}

@-webkit-keyframes anim53 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(430.5deg) translate3d(152px, 330px, 1000px) scale(1);
            transform: rotate(430.5deg) translate3d(152px, 330px, 1000px) scale(1);
  }
}

@keyframes anim53 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(430.5deg) translate3d(152px, 330px, 1000px) scale(1);
            transform: rotate(430.5deg) translate3d(152px, 330px, 1000px) scale(1);
  }
}

.tri:nth-child(54) {
  border-top: 31px solid #9100ff;
  border-right: 31px solid transparent;
  border-left: 31px solid transparent;
  margin-left: -15.5px;
  margin-top: -15.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(32deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(32deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim54 10s infinite linear;
          animation: anim54 10s infinite linear;
  -webkit-animation-delay: -2.7s;
          animation-delay: -2.7s;
  opacity: 0;
}

@-webkit-keyframes anim54 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(48deg) translate3d(250px, 109px, 1000px) scale(1);
            transform: rotate(48deg) translate3d(250px, 109px, 1000px) scale(1);
  }
}

@keyframes anim54 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(48deg) translate3d(250px, 109px, 1000px) scale(1);
            transform: rotate(48deg) translate3d(250px, 109px, 1000px) scale(1);
  }
}

.tri:nth-child(55) {
  border-top: 34px solid #0044ff;
  border-right: 34px solid transparent;
  border-left: 34px solid transparent;
  margin-left: -17px;
  margin-top: -17px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(191deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(191deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim55 10s infinite linear;
          animation: anim55 10s infinite linear;
  -webkit-animation-delay: -2.75s;
          animation-delay: -2.75s;
  opacity: 0;
}

@-webkit-keyframes anim55 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(286.5deg) translate3d(504px, 422px, 1000px) scale(1);
            transform: rotate(286.5deg) translate3d(504px, 422px, 1000px) scale(1);
  }
}

@keyframes anim55 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(286.5deg) translate3d(504px, 422px, 1000px) scale(1);
            transform: rotate(286.5deg) translate3d(504px, 422px, 1000px) scale(1);
  }
}

.tri:nth-child(56) {
  border-top: 50px solid #00ff99;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  margin-left: -25px;
  margin-top: -25px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(207deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(207deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim56 10s infinite linear;
          animation: anim56 10s infinite linear;
  -webkit-animation-delay: -2.8s;
          animation-delay: -2.8s;
  opacity: 0;
}

@-webkit-keyframes anim56 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(310.5deg) translate3d(559px, 938px, 1000px) scale(1);
            transform: rotate(310.5deg) translate3d(559px, 938px, 1000px) scale(1);
  }
}

@keyframes anim56 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(310.5deg) translate3d(559px, 938px, 1000px) scale(1);
            transform: rotate(310.5deg) translate3d(559px, 938px, 1000px) scale(1);
  }
}

.tri:nth-child(57) {
  border-top: 23px solid #0900ff;
  border-right: 23px solid transparent;
  border-left: 23px solid transparent;
  margin-left: -11.5px;
  margin-top: -11.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(211deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(211deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim57 10s infinite linear;
          animation: anim57 10s infinite linear;
  -webkit-animation-delay: -2.85s;
          animation-delay: -2.85s;
  opacity: 0;
}

@-webkit-keyframes anim57 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(316.5deg) translate3d(980px, 3px, 1000px) scale(1);
            transform: rotate(316.5deg) translate3d(980px, 3px, 1000px) scale(1);
  }
}

@keyframes anim57 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(316.5deg) translate3d(980px, 3px, 1000px) scale(1);
            transform: rotate(316.5deg) translate3d(980px, 3px, 1000px) scale(1);
  }
}

.tri:nth-child(58) {
  border-top: 7px solid #ae00ff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -3.5px;
  margin-top: -3.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(196deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(196deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim58 10s infinite linear;
          animation: anim58 10s infinite linear;
  -webkit-animation-delay: -2.9s;
          animation-delay: -2.9s;
  opacity: 0;
}

@-webkit-keyframes anim58 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(294deg) translate3d(273px, 158px, 1000px) scale(1);
            transform: rotate(294deg) translate3d(273px, 158px, 1000px) scale(1);
  }
}

@keyframes anim58 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(294deg) translate3d(273px, 158px, 1000px) scale(1);
            transform: rotate(294deg) translate3d(273px, 158px, 1000px) scale(1);
  }
}

.tri:nth-child(59) {
  border-top: 9px solid #ff0095;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(307deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(307deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim59 10s infinite linear;
          animation: anim59 10s infinite linear;
  -webkit-animation-delay: -2.95s;
          animation-delay: -2.95s;
  opacity: 0;
}

@-webkit-keyframes anim59 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(460.5deg) translate3d(644px, 25px, 1000px) scale(1);
            transform: rotate(460.5deg) translate3d(644px, 25px, 1000px) scale(1);
  }
}

@keyframes anim59 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(460.5deg) translate3d(644px, 25px, 1000px) scale(1);
            transform: rotate(460.5deg) translate3d(644px, 25px, 1000px) scale(1);
  }
}

.tri:nth-child(60) {
  border-top: 37px solid #a6ff00;
  border-right: 37px solid transparent;
  border-left: 37px solid transparent;
  margin-left: -18.5px;
  margin-top: -18.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(250deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(250deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim60 10s infinite linear;
          animation: anim60 10s infinite linear;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  opacity: 0;
}

@-webkit-keyframes anim60 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(375deg) translate3d(771px, 593px, 1000px) scale(1);
            transform: rotate(375deg) translate3d(771px, 593px, 1000px) scale(1);
  }
}

@keyframes anim60 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(375deg) translate3d(771px, 593px, 1000px) scale(1);
            transform: rotate(375deg) translate3d(771px, 593px, 1000px) scale(1);
  }
}

.tri:nth-child(61) {
  border-top: 3px solid #51ff00;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  margin-left: -1.5px;
  margin-top: -1.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(325deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(325deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim61 10s infinite linear;
          animation: anim61 10s infinite linear;
  -webkit-animation-delay: -3.05s;
          animation-delay: -3.05s;
  opacity: 0;
}

@-webkit-keyframes anim61 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(487.5deg) translate3d(516px, 30px, 1000px) scale(1);
            transform: rotate(487.5deg) translate3d(516px, 30px, 1000px) scale(1);
  }
}

@keyframes anim61 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(487.5deg) translate3d(516px, 30px, 1000px) scale(1);
            transform: rotate(487.5deg) translate3d(516px, 30px, 1000px) scale(1);
  }
}

.tri:nth-child(62) {
  border-top: 42px solid #ff9100;
  border-right: 42px solid transparent;
  border-left: 42px solid transparent;
  margin-left: -21px;
  margin-top: -21px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(294deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(294deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim62 10s infinite linear;
          animation: anim62 10s infinite linear;
  -webkit-animation-delay: -3.1s;
          animation-delay: -3.1s;
  opacity: 0;
}

@-webkit-keyframes anim62 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(441deg) translate3d(274px, 743px, 1000px) scale(1);
            transform: rotate(441deg) translate3d(274px, 743px, 1000px) scale(1);
  }
}

@keyframes anim62 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(441deg) translate3d(274px, 743px, 1000px) scale(1);
            transform: rotate(441deg) translate3d(274px, 743px, 1000px) scale(1);
  }
}

.tri:nth-child(63) {
  border-top: 37px solid #009dff;
  border-right: 37px solid transparent;
  border-left: 37px solid transparent;
  margin-left: -18.5px;
  margin-top: -18.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(219deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(219deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim63 10s infinite linear;
          animation: anim63 10s infinite linear;
  -webkit-animation-delay: -3.15s;
          animation-delay: -3.15s;
  opacity: 0;
}

@-webkit-keyframes anim63 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(328.5deg) translate3d(628px, 196px, 1000px) scale(1);
            transform: rotate(328.5deg) translate3d(628px, 196px, 1000px) scale(1);
  }
}

@keyframes anim63 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(328.5deg) translate3d(628px, 196px, 1000px) scale(1);
            transform: rotate(328.5deg) translate3d(628px, 196px, 1000px) scale(1);
  }
}

.tri:nth-child(64) {
  border-top: 40px solid deepskyblue;
  border-right: 40px solid transparent;
  border-left: 40px solid transparent;
  margin-left: -20px;
  margin-top: -20px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(92deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(92deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim64 10s infinite linear;
          animation: anim64 10s infinite linear;
  -webkit-animation-delay: -3.2s;
          animation-delay: -3.2s;
  opacity: 0;
}

@-webkit-keyframes anim64 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(138deg) translate3d(952px, 191px, 1000px) scale(1);
            transform: rotate(138deg) translate3d(952px, 191px, 1000px) scale(1);
  }
}

@keyframes anim64 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(138deg) translate3d(952px, 191px, 1000px) scale(1);
            transform: rotate(138deg) translate3d(952px, 191px, 1000px) scale(1);
  }
}

.tri:nth-child(65) {
  border-top: 45px solid #ff1500;
  border-right: 45px solid transparent;
  border-left: 45px solid transparent;
  margin-left: -22.5px;
  margin-top: -22.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(263deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(263deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim65 10s infinite linear;
          animation: anim65 10s infinite linear;
  -webkit-animation-delay: -3.25s;
          animation-delay: -3.25s;
  opacity: 0;
}

@-webkit-keyframes anim65 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(394.5deg) translate3d(573px, 575px, 1000px) scale(1);
            transform: rotate(394.5deg) translate3d(573px, 575px, 1000px) scale(1);
  }
}

@keyframes anim65 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(394.5deg) translate3d(573px, 575px, 1000px) scale(1);
            transform: rotate(394.5deg) translate3d(573px, 575px, 1000px) scale(1);
  }
}

.tri:nth-child(66) {
  border-top: 27px solid #00ff4d;
  border-right: 27px solid transparent;
  border-left: 27px solid transparent;
  margin-left: -13.5px;
  margin-top: -13.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(256deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(256deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim66 10s infinite linear;
          animation: anim66 10s infinite linear;
  -webkit-animation-delay: -3.3s;
          animation-delay: -3.3s;
  opacity: 0;
}

@-webkit-keyframes anim66 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(384deg) translate3d(504px, 439px, 1000px) scale(1);
            transform: rotate(384deg) translate3d(504px, 439px, 1000px) scale(1);
  }
}

@keyframes anim66 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(384deg) translate3d(504px, 439px, 1000px) scale(1);
            transform: rotate(384deg) translate3d(504px, 439px, 1000px) scale(1);
  }
}

.tri:nth-child(67) {
  border-top: 3px solid #006aff;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  margin-left: -1.5px;
  margin-top: -1.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(289deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(289deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim67 10s infinite linear;
          animation: anim67 10s infinite linear;
  -webkit-animation-delay: -3.35s;
          animation-delay: -3.35s;
  opacity: 0;
}

@-webkit-keyframes anim67 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(433.5deg) translate3d(778px, 366px, 1000px) scale(1);
            transform: rotate(433.5deg) translate3d(778px, 366px, 1000px) scale(1);
  }
}

@keyframes anim67 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(433.5deg) translate3d(778px, 366px, 1000px) scale(1);
            transform: rotate(433.5deg) translate3d(778px, 366px, 1000px) scale(1);
  }
}

.tri:nth-child(68) {
  border-top: 33px solid #003cff;
  border-right: 33px solid transparent;
  border-left: 33px solid transparent;
  margin-left: -16.5px;
  margin-top: -16.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(78deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(78deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim68 10s infinite linear;
          animation: anim68 10s infinite linear;
  -webkit-animation-delay: -3.4s;
          animation-delay: -3.4s;
  opacity: 0;
}

@-webkit-keyframes anim68 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(117deg) translate3d(470px, 820px, 1000px) scale(1);
            transform: rotate(117deg) translate3d(470px, 820px, 1000px) scale(1);
  }
}

@keyframes anim68 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(117deg) translate3d(470px, 820px, 1000px) scale(1);
            transform: rotate(117deg) translate3d(470px, 820px, 1000px) scale(1);
  }
}

.tri:nth-child(69) {
  border-top: 10px solid #ff000d;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  margin-left: -5px;
  margin-top: -5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(1deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(1deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim69 10s infinite linear;
          animation: anim69 10s infinite linear;
  -webkit-animation-delay: -3.45s;
          animation-delay: -3.45s;
  opacity: 0;
}

@-webkit-keyframes anim69 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(1.5deg) translate3d(722px, 818px, 1000px) scale(1);
            transform: rotate(1.5deg) translate3d(722px, 818px, 1000px) scale(1);
  }
}

@keyframes anim69 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(1.5deg) translate3d(722px, 818px, 1000px) scale(1);
            transform: rotate(1.5deg) translate3d(722px, 818px, 1000px) scale(1);
  }
}

.tri:nth-child(70) {
  border-top: 48px solid #a6ff00;
  border-right: 48px solid transparent;
  border-left: 48px solid transparent;
  margin-left: -24px;
  margin-top: -24px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(131deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(131deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim70 10s infinite linear;
          animation: anim70 10s infinite linear;
  -webkit-animation-delay: -3.5s;
          animation-delay: -3.5s;
  opacity: 0;
}

@-webkit-keyframes anim70 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(196.5deg) translate3d(698px, 936px, 1000px) scale(1);
            transform: rotate(196.5deg) translate3d(698px, 936px, 1000px) scale(1);
  }
}

@keyframes anim70 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(196.5deg) translate3d(698px, 936px, 1000px) scale(1);
            transform: rotate(196.5deg) translate3d(698px, 936px, 1000px) scale(1);
  }
}

.tri:nth-child(71) {
  border-top: 19px solid #00ff09;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(174deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(174deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim71 10s infinite linear;
          animation: anim71 10s infinite linear;
  -webkit-animation-delay: -3.55s;
          animation-delay: -3.55s;
  opacity: 0;
}

@-webkit-keyframes anim71 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(261deg) translate3d(569px, 582px, 1000px) scale(1);
            transform: rotate(261deg) translate3d(569px, 582px, 1000px) scale(1);
  }
}

@keyframes anim71 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(261deg) translate3d(569px, 582px, 1000px) scale(1);
            transform: rotate(261deg) translate3d(569px, 582px, 1000px) scale(1);
  }
}

.tri:nth-child(72) {
  border-top: 26px solid #b700ff;
  border-right: 26px solid transparent;
  border-left: 26px solid transparent;
  margin-left: -13px;
  margin-top: -13px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(155deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(155deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim72 10s infinite linear;
          animation: anim72 10s infinite linear;
  -webkit-animation-delay: -3.6s;
          animation-delay: -3.6s;
  opacity: 0;
}

@-webkit-keyframes anim72 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(232.5deg) translate3d(843px, 91px, 1000px) scale(1);
            transform: rotate(232.5deg) translate3d(843px, 91px, 1000px) scale(1);
  }
}

@keyframes anim72 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(232.5deg) translate3d(843px, 91px, 1000px) scale(1);
            transform: rotate(232.5deg) translate3d(843px, 91px, 1000px) scale(1);
  }
}

.tri:nth-child(73) {
  border-top: 9px solid #00eeff;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(256deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(256deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim73 10s infinite linear;
          animation: anim73 10s infinite linear;
  -webkit-animation-delay: -3.65s;
          animation-delay: -3.65s;
  opacity: 0;
}

@-webkit-keyframes anim73 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(384deg) translate3d(140px, 22px, 1000px) scale(1);
            transform: rotate(384deg) translate3d(140px, 22px, 1000px) scale(1);
  }
}

@keyframes anim73 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(384deg) translate3d(140px, 22px, 1000px) scale(1);
            transform: rotate(384deg) translate3d(140px, 22px, 1000px) scale(1);
  }
}

.tri:nth-child(74) {
  border-top: 40px solid #0095ff;
  border-right: 40px solid transparent;
  border-left: 40px solid transparent;
  margin-left: -20px;
  margin-top: -20px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(186deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(186deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim74 10s infinite linear;
          animation: anim74 10s infinite linear;
  -webkit-animation-delay: -3.7s;
          animation-delay: -3.7s;
  opacity: 0;
}

@-webkit-keyframes anim74 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(279deg) translate3d(738px, 889px, 1000px) scale(1);
            transform: rotate(279deg) translate3d(738px, 889px, 1000px) scale(1);
  }
}

@keyframes anim74 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(279deg) translate3d(738px, 889px, 1000px) scale(1);
            transform: rotate(279deg) translate3d(738px, 889px, 1000px) scale(1);
  }
}

.tri:nth-child(75) {
  border-top: 2px solid #0080ff;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  margin-left: -1px;
  margin-top: -1px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(250deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(250deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim75 10s infinite linear;
          animation: anim75 10s infinite linear;
  -webkit-animation-delay: -3.75s;
          animation-delay: -3.75s;
  opacity: 0;
}

@-webkit-keyframes anim75 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(375deg) translate3d(519px, 218px, 1000px) scale(1);
            transform: rotate(375deg) translate3d(519px, 218px, 1000px) scale(1);
  }
}

@keyframes anim75 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(375deg) translate3d(519px, 218px, 1000px) scale(1);
            transform: rotate(375deg) translate3d(519px, 218px, 1000px) scale(1);
  }
}

.tri:nth-child(76) {
  border-top: 4px solid #00ff0d;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(150deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(150deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim76 10s infinite linear;
          animation: anim76 10s infinite linear;
  -webkit-animation-delay: -3.8s;
          animation-delay: -3.8s;
  opacity: 0;
}

@-webkit-keyframes anim76 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(225deg) translate3d(70px, 204px, 1000px) scale(1);
            transform: rotate(225deg) translate3d(70px, 204px, 1000px) scale(1);
  }
}

@keyframes anim76 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(225deg) translate3d(70px, 204px, 1000px) scale(1);
            transform: rotate(225deg) translate3d(70px, 204px, 1000px) scale(1);
  }
}

.tri:nth-child(77) {
  border-top: 49px solid #d000ff;
  border-right: 49px solid transparent;
  border-left: 49px solid transparent;
  margin-left: -24.5px;
  margin-top: -24.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(63deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(63deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim77 10s infinite linear;
          animation: anim77 10s infinite linear;
  -webkit-animation-delay: -3.85s;
          animation-delay: -3.85s;
  opacity: 0;
}

@-webkit-keyframes anim77 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(94.5deg) translate3d(20px, 279px, 1000px) scale(1);
            transform: rotate(94.5deg) translate3d(20px, 279px, 1000px) scale(1);
  }
}

@keyframes anim77 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(94.5deg) translate3d(20px, 279px, 1000px) scale(1);
            transform: rotate(94.5deg) translate3d(20px, 279px, 1000px) scale(1);
  }
}

.tri:nth-child(78) {
  border-top: 44px solid red;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(322deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(322deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim78 10s infinite linear;
          animation: anim78 10s infinite linear;
  -webkit-animation-delay: -3.9s;
          animation-delay: -3.9s;
  opacity: 0;
}

@-webkit-keyframes anim78 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(483deg) translate3d(527px, 650px, 1000px) scale(1);
            transform: rotate(483deg) translate3d(527px, 650px, 1000px) scale(1);
  }
}

@keyframes anim78 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(483deg) translate3d(527px, 650px, 1000px) scale(1);
            transform: rotate(483deg) translate3d(527px, 650px, 1000px) scale(1);
  }
}

.tri:nth-child(79) {
  border-top: 29px solid #00ff2b;
  border-right: 29px solid transparent;
  border-left: 29px solid transparent;
  margin-left: -14.5px;
  margin-top: -14.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(114deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(114deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim79 10s infinite linear;
          animation: anim79 10s infinite linear;
  -webkit-animation-delay: -3.95s;
          animation-delay: -3.95s;
  opacity: 0;
}

@-webkit-keyframes anim79 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(171deg) translate3d(992px, 594px, 1000px) scale(1);
            transform: rotate(171deg) translate3d(992px, 594px, 1000px) scale(1);
  }
}

@keyframes anim79 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(171deg) translate3d(992px, 594px, 1000px) scale(1);
            transform: rotate(171deg) translate3d(992px, 594px, 1000px) scale(1);
  }
}

.tri:nth-child(80) {
  border-top: 44px solid #00bbff;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(170deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(170deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim80 10s infinite linear;
          animation: anim80 10s infinite linear;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  opacity: 0;
}

@-webkit-keyframes anim80 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(255deg) translate3d(960px, 340px, 1000px) scale(1);
            transform: rotate(255deg) translate3d(960px, 340px, 1000px) scale(1);
  }
}

@keyframes anim80 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(255deg) translate3d(960px, 340px, 1000px) scale(1);
            transform: rotate(255deg) translate3d(960px, 340px, 1000px) scale(1);
  }
}

.tri:nth-child(81) {
  border-top: 47px solid #ffc400;
  border-right: 47px solid transparent;
  border-left: 47px solid transparent;
  margin-left: -23.5px;
  margin-top: -23.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(249deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(249deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim81 10s infinite linear;
          animation: anim81 10s infinite linear;
  -webkit-animation-delay: -4.05s;
          animation-delay: -4.05s;
  opacity: 0;
}

@-webkit-keyframes anim81 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(373.5deg) translate3d(67px, 923px, 1000px) scale(1);
            transform: rotate(373.5deg) translate3d(67px, 923px, 1000px) scale(1);
  }
}

@keyframes anim81 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(373.5deg) translate3d(67px, 923px, 1000px) scale(1);
            transform: rotate(373.5deg) translate3d(67px, 923px, 1000px) scale(1);
  }
}

.tri:nth-child(82) {
  border-top: 21px solid #2bff00;
  border-right: 21px solid transparent;
  border-left: 21px solid transparent;
  margin-left: -10.5px;
  margin-top: -10.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(56deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(56deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim82 10s infinite linear;
          animation: anim82 10s infinite linear;
  -webkit-animation-delay: -4.1s;
          animation-delay: -4.1s;
  opacity: 0;
}

@-webkit-keyframes anim82 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(84deg) translate3d(279px, 357px, 1000px) scale(1);
            transform: rotate(84deg) translate3d(279px, 357px, 1000px) scale(1);
  }
}

@keyframes anim82 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(84deg) translate3d(279px, 357px, 1000px) scale(1);
            transform: rotate(84deg) translate3d(279px, 357px, 1000px) scale(1);
  }
}

.tri:nth-child(83) {
  border-top: 34px solid #ff0900;
  border-right: 34px solid transparent;
  border-left: 34px solid transparent;
  margin-left: -17px;
  margin-top: -17px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(125deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(125deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim83 10s infinite linear;
          animation: anim83 10s infinite linear;
  -webkit-animation-delay: -4.15s;
          animation-delay: -4.15s;
  opacity: 0;
}

@-webkit-keyframes anim83 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(187.5deg) translate3d(253px, 409px, 1000px) scale(1);
            transform: rotate(187.5deg) translate3d(253px, 409px, 1000px) scale(1);
  }
}

@keyframes anim83 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(187.5deg) translate3d(253px, 409px, 1000px) scale(1);
            transform: rotate(187.5deg) translate3d(253px, 409px, 1000px) scale(1);
  }
}

.tri:nth-child(84) {
  border-top: 38px solid #3700ff;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(121deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(121deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim84 10s infinite linear;
          animation: anim84 10s infinite linear;
  -webkit-animation-delay: -4.2s;
          animation-delay: -4.2s;
  opacity: 0;
}

@-webkit-keyframes anim84 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(181.5deg) translate3d(647px, 284px, 1000px) scale(1);
            transform: rotate(181.5deg) translate3d(647px, 284px, 1000px) scale(1);
  }
}

@keyframes anim84 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(181.5deg) translate3d(647px, 284px, 1000px) scale(1);
            transform: rotate(181.5deg) translate3d(647px, 284px, 1000px) scale(1);
  }
}

.tri:nth-child(85) {
  border-top: 9px solid #00ff04;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(153deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(153deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim85 10s infinite linear;
          animation: anim85 10s infinite linear;
  -webkit-animation-delay: -4.25s;
          animation-delay: -4.25s;
  opacity: 0;
}

@-webkit-keyframes anim85 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(229.5deg) translate3d(841px, 780px, 1000px) scale(1);
            transform: rotate(229.5deg) translate3d(841px, 780px, 1000px) scale(1);
  }
}

@keyframes anim85 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(229.5deg) translate3d(841px, 780px, 1000px) scale(1);
            transform: rotate(229.5deg) translate3d(841px, 780px, 1000px) scale(1);
  }
}

.tri:nth-child(86) {
  border-top: 4px solid #1eff00;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(41deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(41deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim86 10s infinite linear;
          animation: anim86 10s infinite linear;
  -webkit-animation-delay: -4.3s;
          animation-delay: -4.3s;
  opacity: 0;
}

@-webkit-keyframes anim86 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(61.5deg) translate3d(700px, 638px, 1000px) scale(1);
            transform: rotate(61.5deg) translate3d(700px, 638px, 1000px) scale(1);
  }
}

@keyframes anim86 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(61.5deg) translate3d(700px, 638px, 1000px) scale(1);
            transform: rotate(61.5deg) translate3d(700px, 638px, 1000px) scale(1);
  }
}

.tri:nth-child(87) {
  border-top: 31px solid #ff00b7;
  border-right: 31px solid transparent;
  border-left: 31px solid transparent;
  margin-left: -15.5px;
  margin-top: -15.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(20deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(20deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim87 10s infinite linear;
          animation: anim87 10s infinite linear;
  -webkit-animation-delay: -4.35s;
          animation-delay: -4.35s;
  opacity: 0;
}

@-webkit-keyframes anim87 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(30deg) translate3d(596px, 112px, 1000px) scale(1);
            transform: rotate(30deg) translate3d(596px, 112px, 1000px) scale(1);
  }
}

@keyframes anim87 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(30deg) translate3d(596px, 112px, 1000px) scale(1);
            transform: rotate(30deg) translate3d(596px, 112px, 1000px) scale(1);
  }
}

.tri:nth-child(88) {
  border-top: 43px solid #ff2600;
  border-right: 43px solid transparent;
  border-left: 43px solid transparent;
  margin-left: -21.5px;
  margin-top: -21.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(240deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(240deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim88 10s infinite linear;
          animation: anim88 10s infinite linear;
  -webkit-animation-delay: -4.4s;
          animation-delay: -4.4s;
  opacity: 0;
}

@-webkit-keyframes anim88 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(360deg) translate3d(880px, 136px, 1000px) scale(1);
            transform: rotate(360deg) translate3d(880px, 136px, 1000px) scale(1);
  }
}

@keyframes anim88 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(360deg) translate3d(880px, 136px, 1000px) scale(1);
            transform: rotate(360deg) translate3d(880px, 136px, 1000px) scale(1);
  }
}

.tri:nth-child(89) {
  border-top: 6px solid #ae00ff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(288deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(288deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim89 10s infinite linear;
          animation: anim89 10s infinite linear;
  -webkit-animation-delay: -4.45s;
          animation-delay: -4.45s;
  opacity: 0;
}

@-webkit-keyframes anim89 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(432deg) translate3d(938px, 290px, 1000px) scale(1);
            transform: rotate(432deg) translate3d(938px, 290px, 1000px) scale(1);
  }
}

@keyframes anim89 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(432deg) translate3d(938px, 290px, 1000px) scale(1);
            transform: rotate(432deg) translate3d(938px, 290px, 1000px) scale(1);
  }
}

.tri:nth-child(90) {
  border-top: 4px solid #ff1500;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(150deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(150deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim90 10s infinite linear;
          animation: anim90 10s infinite linear;
  -webkit-animation-delay: -4.5s;
          animation-delay: -4.5s;
  opacity: 0;
}

@-webkit-keyframes anim90 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(225deg) translate3d(332px, 743px, 1000px) scale(1);
            transform: rotate(225deg) translate3d(332px, 743px, 1000px) scale(1);
  }
}

@keyframes anim90 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(225deg) translate3d(332px, 743px, 1000px) scale(1);
            transform: rotate(225deg) translate3d(332px, 743px, 1000px) scale(1);
  }
}

.tri:nth-child(91) {
  border-top: 33px solid #f7ff00;
  border-right: 33px solid transparent;
  border-left: 33px solid transparent;
  margin-left: -16.5px;
  margin-top: -16.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(353deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(353deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim91 10s infinite linear;
          animation: anim91 10s infinite linear;
  -webkit-animation-delay: -4.55s;
          animation-delay: -4.55s;
  opacity: 0;
}

@-webkit-keyframes anim91 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(529.5deg) translate3d(677px, 545px, 1000px) scale(1);
            transform: rotate(529.5deg) translate3d(677px, 545px, 1000px) scale(1);
  }
}

@keyframes anim91 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(529.5deg) translate3d(677px, 545px, 1000px) scale(1);
            transform: rotate(529.5deg) translate3d(677px, 545px, 1000px) scale(1);
  }
}

.tri:nth-child(92) {
  border-top: 24px solid #ff2600;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  margin-left: -12px;
  margin-top: -12px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(339deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(339deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim92 10s infinite linear;
          animation: anim92 10s infinite linear;
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
  opacity: 0;
}

@-webkit-keyframes anim92 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(508.5deg) translate3d(271px, 898px, 1000px) scale(1);
            transform: rotate(508.5deg) translate3d(271px, 898px, 1000px) scale(1);
  }
}

@keyframes anim92 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(508.5deg) translate3d(271px, 898px, 1000px) scale(1);
            transform: rotate(508.5deg) translate3d(271px, 898px, 1000px) scale(1);
  }
}

.tri:nth-child(93) {
  border-top: 5px solid #ff00f7;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-left: -2.5px;
  margin-top: -2.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(203deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(203deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim93 10s infinite linear;
          animation: anim93 10s infinite linear;
  -webkit-animation-delay: -4.65s;
          animation-delay: -4.65s;
  opacity: 0;
}

@-webkit-keyframes anim93 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(304.5deg) translate3d(49px, 6px, 1000px) scale(1);
            transform: rotate(304.5deg) translate3d(49px, 6px, 1000px) scale(1);
  }
}

@keyframes anim93 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(304.5deg) translate3d(49px, 6px, 1000px) scale(1);
            transform: rotate(304.5deg) translate3d(49px, 6px, 1000px) scale(1);
  }
}

.tri:nth-child(94) {
  border-top: 37px solid #001aff;
  border-right: 37px solid transparent;
  border-left: 37px solid transparent;
  margin-left: -18.5px;
  margin-top: -18.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(229deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(229deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim94 10s infinite linear;
          animation: anim94 10s infinite linear;
  -webkit-animation-delay: -4.7s;
          animation-delay: -4.7s;
  opacity: 0;
}

@-webkit-keyframes anim94 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(343.5deg) translate3d(912px, 46px, 1000px) scale(1);
            transform: rotate(343.5deg) translate3d(912px, 46px, 1000px) scale(1);
  }
}

@keyframes anim94 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(343.5deg) translate3d(912px, 46px, 1000px) scale(1);
            transform: rotate(343.5deg) translate3d(912px, 46px, 1000px) scale(1);
  }
}

.tri:nth-child(95) {
  border-top: 34px solid #5e00ff;
  border-right: 34px solid transparent;
  border-left: 34px solid transparent;
  margin-left: -17px;
  margin-top: -17px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(58deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(58deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim95 10s infinite linear;
          animation: anim95 10s infinite linear;
  -webkit-animation-delay: -4.75s;
          animation-delay: -4.75s;
  opacity: 0;
}

@-webkit-keyframes anim95 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(87deg) translate3d(681px, 132px, 1000px) scale(1);
            transform: rotate(87deg) translate3d(681px, 132px, 1000px) scale(1);
  }
}

@keyframes anim95 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(87deg) translate3d(681px, 132px, 1000px) scale(1);
            transform: rotate(87deg) translate3d(681px, 132px, 1000px) scale(1);
  }
}

.tri:nth-child(96) {
  border-top: 44px solid #00ff2b;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(118deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(118deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim96 10s infinite linear;
          animation: anim96 10s infinite linear;
  -webkit-animation-delay: -4.8s;
          animation-delay: -4.8s;
  opacity: 0;
}

@-webkit-keyframes anim96 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(177deg) translate3d(763px, 787px, 1000px) scale(1);
            transform: rotate(177deg) translate3d(763px, 787px, 1000px) scale(1);
  }
}

@keyframes anim96 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(177deg) translate3d(763px, 787px, 1000px) scale(1);
            transform: rotate(177deg) translate3d(763px, 787px, 1000px) scale(1);
  }
}

.tri:nth-child(97) {
  border-top: 7px solid #8c00ff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -3.5px;
  margin-top: -3.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(97deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(97deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim97 10s infinite linear;
          animation: anim97 10s infinite linear;
  -webkit-animation-delay: -4.85s;
          animation-delay: -4.85s;
  opacity: 0;
}

@-webkit-keyframes anim97 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(145.5deg) translate3d(371px, 961px, 1000px) scale(1);
            transform: rotate(145.5deg) translate3d(371px, 961px, 1000px) scale(1);
  }
}

@keyframes anim97 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(145.5deg) translate3d(371px, 961px, 1000px) scale(1);
            transform: rotate(145.5deg) translate3d(371px, 961px, 1000px) scale(1);
  }
}

.tri:nth-child(98) {
  border-top: 8px solid #00f2ff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  margin-left: -4px;
  margin-top: -4px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(261deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(261deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim98 10s infinite linear;
          animation: anim98 10s infinite linear;
  -webkit-animation-delay: -4.9s;
          animation-delay: -4.9s;
  opacity: 0;
}

@-webkit-keyframes anim98 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(391.5deg) translate3d(681px, 8px, 1000px) scale(1);
            transform: rotate(391.5deg) translate3d(681px, 8px, 1000px) scale(1);
  }
}

@keyframes anim98 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(391.5deg) translate3d(681px, 8px, 1000px) scale(1);
            transform: rotate(391.5deg) translate3d(681px, 8px, 1000px) scale(1);
  }
}

.tri:nth-child(99) {
  border-top: 25px solid #0084ff;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
  margin-left: -12.5px;
  margin-top: -12.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(307deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(307deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim99 10s infinite linear;
          animation: anim99 10s infinite linear;
  -webkit-animation-delay: -4.95s;
          animation-delay: -4.95s;
  opacity: 0;
}

@-webkit-keyframes anim99 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(460.5deg) translate3d(343px, 628px, 1000px) scale(1);
            transform: rotate(460.5deg) translate3d(343px, 628px, 1000px) scale(1);
  }
}

@keyframes anim99 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(460.5deg) translate3d(343px, 628px, 1000px) scale(1);
            transform: rotate(460.5deg) translate3d(343px, 628px, 1000px) scale(1);
  }
}

.tri:nth-child(100) {
  border-top: 47px solid #f700ff;
  border-right: 47px solid transparent;
  border-left: 47px solid transparent;
  margin-left: -23.5px;
  margin-top: -23.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(297deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(297deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim100 10s infinite linear;
          animation: anim100 10s infinite linear;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  opacity: 0;
}

@-webkit-keyframes anim100 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(445.5deg) translate3d(409px, 413px, 1000px) scale(1);
            transform: rotate(445.5deg) translate3d(409px, 413px, 1000px) scale(1);
  }
}

@keyframes anim100 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(445.5deg) translate3d(409px, 413px, 1000px) scale(1);
            transform: rotate(445.5deg) translate3d(409px, 413px, 1000px) scale(1);
  }
}

.tri:nth-child(101) {
  border-top: 4px solid #ff006f;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(128deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(128deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim101 10s infinite linear;
          animation: anim101 10s infinite linear;
  -webkit-animation-delay: -5.05s;
          animation-delay: -5.05s;
  opacity: 0;
}

@-webkit-keyframes anim101 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(192deg) translate3d(270px, 808px, 1000px) scale(1);
            transform: rotate(192deg) translate3d(270px, 808px, 1000px) scale(1);
  }
}

@keyframes anim101 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(192deg) translate3d(270px, 808px, 1000px) scale(1);
            transform: rotate(192deg) translate3d(270px, 808px, 1000px) scale(1);
  }
}

.tri:nth-child(102) {
  border-top: 15px solid #00ff95;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  margin-left: -7.5px;
  margin-top: -7.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(229deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(229deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim102 10s infinite linear;
          animation: anim102 10s infinite linear;
  -webkit-animation-delay: -5.1s;
          animation-delay: -5.1s;
  opacity: 0;
}

@-webkit-keyframes anim102 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(343.5deg) translate3d(872px, 819px, 1000px) scale(1);
            transform: rotate(343.5deg) translate3d(872px, 819px, 1000px) scale(1);
  }
}

@keyframes anim102 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(343.5deg) translate3d(872px, 819px, 1000px) scale(1);
            transform: rotate(343.5deg) translate3d(872px, 819px, 1000px) scale(1);
  }
}

.tri:nth-child(103) {
  border-top: 31px solid #2600ff;
  border-right: 31px solid transparent;
  border-left: 31px solid transparent;
  margin-left: -15.5px;
  margin-top: -15.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(43deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(43deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim103 10s infinite linear;
          animation: anim103 10s infinite linear;
  -webkit-animation-delay: -5.15s;
          animation-delay: -5.15s;
  opacity: 0;
}

@-webkit-keyframes anim103 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(64.5deg) translate3d(931px, 37px, 1000px) scale(1);
            transform: rotate(64.5deg) translate3d(931px, 37px, 1000px) scale(1);
  }
}

@keyframes anim103 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(64.5deg) translate3d(931px, 37px, 1000px) scale(1);
            transform: rotate(64.5deg) translate3d(931px, 37px, 1000px) scale(1);
  }
}

.tri:nth-child(104) {
  border-top: 17px solid #6200ff;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
  margin-left: -8.5px;
  margin-top: -8.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(152deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(152deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim104 10s infinite linear;
          animation: anim104 10s infinite linear;
  -webkit-animation-delay: -5.2s;
          animation-delay: -5.2s;
  opacity: 0;
}

@-webkit-keyframes anim104 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(228deg) translate3d(404px, 958px, 1000px) scale(1);
            transform: rotate(228deg) translate3d(404px, 958px, 1000px) scale(1);
  }
}

@keyframes anim104 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(228deg) translate3d(404px, 958px, 1000px) scale(1);
            transform: rotate(228deg) translate3d(404px, 958px, 1000px) scale(1);
  }
}

.tri:nth-child(105) {
  border-top: 35px solid #5e00ff;
  border-right: 35px solid transparent;
  border-left: 35px solid transparent;
  margin-left: -17.5px;
  margin-top: -17.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(105deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(105deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim105 10s infinite linear;
          animation: anim105 10s infinite linear;
  -webkit-animation-delay: -5.25s;
          animation-delay: -5.25s;
  opacity: 0;
}

@-webkit-keyframes anim105 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(157.5deg) translate3d(392px, 995px, 1000px) scale(1);
            transform: rotate(157.5deg) translate3d(392px, 995px, 1000px) scale(1);
  }
}

@keyframes anim105 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(157.5deg) translate3d(392px, 995px, 1000px) scale(1);
            transform: rotate(157.5deg) translate3d(392px, 995px, 1000px) scale(1);
  }
}

.tri:nth-child(106) {
  border-top: 24px solid #00ff44;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  margin-left: -12px;
  margin-top: -12px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(188deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(188deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim106 10s infinite linear;
          animation: anim106 10s infinite linear;
  -webkit-animation-delay: -5.3s;
          animation-delay: -5.3s;
  opacity: 0;
}

@-webkit-keyframes anim106 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(282deg) translate3d(504px, 370px, 1000px) scale(1);
            transform: rotate(282deg) translate3d(504px, 370px, 1000px) scale(1);
  }
}

@keyframes anim106 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(282deg) translate3d(504px, 370px, 1000px) scale(1);
            transform: rotate(282deg) translate3d(504px, 370px, 1000px) scale(1);
  }
}

.tri:nth-child(107) {
  border-top: 1px solid #00ff55;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-left: -0.5px;
  margin-top: -0.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(257deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(257deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim107 10s infinite linear;
          animation: anim107 10s infinite linear;
  -webkit-animation-delay: -5.35s;
          animation-delay: -5.35s;
  opacity: 0;
}

@-webkit-keyframes anim107 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(385.5deg) translate3d(640px, 733px, 1000px) scale(1);
            transform: rotate(385.5deg) translate3d(640px, 733px, 1000px) scale(1);
  }
}

@keyframes anim107 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(385.5deg) translate3d(640px, 733px, 1000px) scale(1);
            transform: rotate(385.5deg) translate3d(640px, 733px, 1000px) scale(1);
  }
}

.tri:nth-child(108) {
  border-top: 4px solid #ff2600;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-left: -2px;
  margin-top: -2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(271deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(271deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim108 10s infinite linear;
          animation: anim108 10s infinite linear;
  -webkit-animation-delay: -5.4s;
          animation-delay: -5.4s;
  opacity: 0;
}

@-webkit-keyframes anim108 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(406.5deg) translate3d(132px, 14px, 1000px) scale(1);
            transform: rotate(406.5deg) translate3d(132px, 14px, 1000px) scale(1);
  }
}

@keyframes anim108 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(406.5deg) translate3d(132px, 14px, 1000px) scale(1);
            transform: rotate(406.5deg) translate3d(132px, 14px, 1000px) scale(1);
  }
}

.tri:nth-child(109) {
  border-top: 50px solid #ff00aa;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  margin-left: -25px;
  margin-top: -25px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(358deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(358deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim109 10s infinite linear;
          animation: anim109 10s infinite linear;
  -webkit-animation-delay: -5.45s;
          animation-delay: -5.45s;
  opacity: 0;
}

@-webkit-keyframes anim109 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(537deg) translate3d(997px, 911px, 1000px) scale(1);
            transform: rotate(537deg) translate3d(997px, 911px, 1000px) scale(1);
  }
}

@keyframes anim109 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(537deg) translate3d(997px, 911px, 1000px) scale(1);
            transform: rotate(537deg) translate3d(997px, 911px, 1000px) scale(1);
  }
}

.tri:nth-child(110) {
  border-top: 33px solid #ddff00;
  border-right: 33px solid transparent;
  border-left: 33px solid transparent;
  margin-left: -16.5px;
  margin-top: -16.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(339deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(339deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim110 10s infinite linear;
          animation: anim110 10s infinite linear;
  -webkit-animation-delay: -5.5s;
          animation-delay: -5.5s;
  opacity: 0;
}

@-webkit-keyframes anim110 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(508.5deg) translate3d(319px, 32px, 1000px) scale(1);
            transform: rotate(508.5deg) translate3d(319px, 32px, 1000px) scale(1);
  }
}

@keyframes anim110 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(508.5deg) translate3d(319px, 32px, 1000px) scale(1);
            transform: rotate(508.5deg) translate3d(319px, 32px, 1000px) scale(1);
  }
}

.tri:nth-child(111) {
  border-top: 38px solid #ff7700;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(223deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(223deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim111 10s infinite linear;
          animation: anim111 10s infinite linear;
  -webkit-animation-delay: -5.55s;
          animation-delay: -5.55s;
  opacity: 0;
}

@-webkit-keyframes anim111 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(334.5deg) translate3d(115px, 578px, 1000px) scale(1);
            transform: rotate(334.5deg) translate3d(115px, 578px, 1000px) scale(1);
  }
}

@keyframes anim111 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(334.5deg) translate3d(115px, 578px, 1000px) scale(1);
            transform: rotate(334.5deg) translate3d(115px, 578px, 1000px) scale(1);
  }
}

.tri:nth-child(112) {
  border-top: 6px solid #ff00bf;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(230deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(230deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim112 10s infinite linear;
          animation: anim112 10s infinite linear;
  -webkit-animation-delay: -5.6s;
          animation-delay: -5.6s;
  opacity: 0;
}

@-webkit-keyframes anim112 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(345deg) translate3d(17px, 604px, 1000px) scale(1);
            transform: rotate(345deg) translate3d(17px, 604px, 1000px) scale(1);
  }
}

@keyframes anim112 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(345deg) translate3d(17px, 604px, 1000px) scale(1);
            transform: rotate(345deg) translate3d(17px, 604px, 1000px) scale(1);
  }
}

.tri:nth-child(113) {
  border-top: 20px solid #6f00ff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(274deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(274deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim113 10s infinite linear;
          animation: anim113 10s infinite linear;
  -webkit-animation-delay: -5.65s;
          animation-delay: -5.65s;
  opacity: 0;
}

@-webkit-keyframes anim113 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(411deg) translate3d(735px, 684px, 1000px) scale(1);
            transform: rotate(411deg) translate3d(735px, 684px, 1000px) scale(1);
  }
}

@keyframes anim113 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(411deg) translate3d(735px, 684px, 1000px) scale(1);
            transform: rotate(411deg) translate3d(735px, 684px, 1000px) scale(1);
  }
}

.tri:nth-child(114) {
  border-top: 41px solid #cc00ff;
  border-right: 41px solid transparent;
  border-left: 41px solid transparent;
  margin-left: -20.5px;
  margin-top: -20.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(222deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(222deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim114 10s infinite linear;
          animation: anim114 10s infinite linear;
  -webkit-animation-delay: -5.7s;
          animation-delay: -5.7s;
  opacity: 0;
}

@-webkit-keyframes anim114 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(333deg) translate3d(886px, 788px, 1000px) scale(1);
            transform: rotate(333deg) translate3d(886px, 788px, 1000px) scale(1);
  }
}

@keyframes anim114 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(333deg) translate3d(886px, 788px, 1000px) scale(1);
            transform: rotate(333deg) translate3d(886px, 788px, 1000px) scale(1);
  }
}

.tri:nth-child(115) {
  border-top: 43px solid #ff00b7;
  border-right: 43px solid transparent;
  border-left: 43px solid transparent;
  margin-left: -21.5px;
  margin-top: -21.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(161deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(161deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim115 10s infinite linear;
          animation: anim115 10s infinite linear;
  -webkit-animation-delay: -5.75s;
          animation-delay: -5.75s;
  opacity: 0;
}

@-webkit-keyframes anim115 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(241.5deg) translate3d(377px, 595px, 1000px) scale(1);
            transform: rotate(241.5deg) translate3d(377px, 595px, 1000px) scale(1);
  }
}

@keyframes anim115 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(241.5deg) translate3d(377px, 595px, 1000px) scale(1);
            transform: rotate(241.5deg) translate3d(377px, 595px, 1000px) scale(1);
  }
}

.tri:nth-child(116) {
  border-top: 26px solid #2bff00;
  border-right: 26px solid transparent;
  border-left: 26px solid transparent;
  margin-left: -13px;
  margin-top: -13px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(337deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(337deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim116 10s infinite linear;
          animation: anim116 10s infinite linear;
  -webkit-animation-delay: -5.8s;
          animation-delay: -5.8s;
  opacity: 0;
}

@-webkit-keyframes anim116 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(505.5deg) translate3d(535px, 151px, 1000px) scale(1);
            transform: rotate(505.5deg) translate3d(535px, 151px, 1000px) scale(1);
  }
}

@keyframes anim116 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(505.5deg) translate3d(535px, 151px, 1000px) scale(1);
            transform: rotate(505.5deg) translate3d(535px, 151px, 1000px) scale(1);
  }
}

.tri:nth-child(117) {
  border-top: 29px solid #5e00ff;
  border-right: 29px solid transparent;
  border-left: 29px solid transparent;
  margin-left: -14.5px;
  margin-top: -14.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(188deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(188deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim117 10s infinite linear;
          animation: anim117 10s infinite linear;
  -webkit-animation-delay: -5.85s;
          animation-delay: -5.85s;
  opacity: 0;
}

@-webkit-keyframes anim117 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(282deg) translate3d(302px, 781px, 1000px) scale(1);
            transform: rotate(282deg) translate3d(302px, 781px, 1000px) scale(1);
  }
}

@keyframes anim117 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(282deg) translate3d(302px, 781px, 1000px) scale(1);
            transform: rotate(282deg) translate3d(302px, 781px, 1000px) scale(1);
  }
}

.tri:nth-child(118) {
  border-top: 7px solid #0084ff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -3.5px;
  margin-top: -3.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(139deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(139deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim118 10s infinite linear;
          animation: anim118 10s infinite linear;
  -webkit-animation-delay: -5.9s;
          animation-delay: -5.9s;
  opacity: 0;
}

@-webkit-keyframes anim118 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(208.5deg) translate3d(55px, 910px, 1000px) scale(1);
            transform: rotate(208.5deg) translate3d(55px, 910px, 1000px) scale(1);
  }
}

@keyframes anim118 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(208.5deg) translate3d(55px, 910px, 1000px) scale(1);
            transform: rotate(208.5deg) translate3d(55px, 910px, 1000px) scale(1);
  }
}

.tri:nth-child(119) {
  border-top: 16px solid #00ff1e;
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  margin-left: -8px;
  margin-top: -8px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(232deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(232deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim119 10s infinite linear;
          animation: anim119 10s infinite linear;
  -webkit-animation-delay: -5.95s;
          animation-delay: -5.95s;
  opacity: 0;
}

@-webkit-keyframes anim119 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(348deg) translate3d(725px, 175px, 1000px) scale(1);
            transform: rotate(348deg) translate3d(725px, 175px, 1000px) scale(1);
  }
}

@keyframes anim119 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(348deg) translate3d(725px, 175px, 1000px) scale(1);
            transform: rotate(348deg) translate3d(725px, 175px, 1000px) scale(1);
  }
}

.tri:nth-child(120) {
  border-top: 15px solid #fff200;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  margin-left: -7.5px;
  margin-top: -7.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(181deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(181deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim120 10s infinite linear;
          animation: anim120 10s infinite linear;
  -webkit-animation-delay: -6s;
          animation-delay: -6s;
  opacity: 0;
}

@-webkit-keyframes anim120 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(271.5deg) translate3d(210px, 950px, 1000px) scale(1);
            transform: rotate(271.5deg) translate3d(210px, 950px, 1000px) scale(1);
  }
}

@keyframes anim120 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(271.5deg) translate3d(210px, 950px, 1000px) scale(1);
            transform: rotate(271.5deg) translate3d(210px, 950px, 1000px) scale(1);
  }
}

.tri:nth-child(121) {
  border-top: 31px solid #00fbff;
  border-right: 31px solid transparent;
  border-left: 31px solid transparent;
  margin-left: -15.5px;
  margin-top: -15.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(122deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(122deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim121 10s infinite linear;
          animation: anim121 10s infinite linear;
  -webkit-animation-delay: -6.05s;
          animation-delay: -6.05s;
  opacity: 0;
}

@-webkit-keyframes anim121 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(183deg) translate3d(123px, 772px, 1000px) scale(1);
            transform: rotate(183deg) translate3d(123px, 772px, 1000px) scale(1);
  }
}

@keyframes anim121 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(183deg) translate3d(123px, 772px, 1000px) scale(1);
            transform: rotate(183deg) translate3d(123px, 772px, 1000px) scale(1);
  }
}

.tri:nth-child(122) {
  border-top: 39px solid #00ff62;
  border-right: 39px solid transparent;
  border-left: 39px solid transparent;
  margin-left: -19.5px;
  margin-top: -19.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(94deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(94deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim122 10s infinite linear;
          animation: anim122 10s infinite linear;
  -webkit-animation-delay: -6.1s;
          animation-delay: -6.1s;
  opacity: 0;
}

@-webkit-keyframes anim122 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(141deg) translate3d(282px, 578px, 1000px) scale(1);
            transform: rotate(141deg) translate3d(282px, 578px, 1000px) scale(1);
  }
}

@keyframes anim122 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(141deg) translate3d(282px, 578px, 1000px) scale(1);
            transform: rotate(141deg) translate3d(282px, 578px, 1000px) scale(1);
  }
}

.tri:nth-child(123) {
  border-top: 48px solid #0059ff;
  border-right: 48px solid transparent;
  border-left: 48px solid transparent;
  margin-left: -24px;
  margin-top: -24px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(82deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(82deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim123 10s infinite linear;
          animation: anim123 10s infinite linear;
  -webkit-animation-delay: -6.15s;
          animation-delay: -6.15s;
  opacity: 0;
}

@-webkit-keyframes anim123 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(123deg) translate3d(819px, 485px, 1000px) scale(1);
            transform: rotate(123deg) translate3d(819px, 485px, 1000px) scale(1);
  }
}

@keyframes anim123 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(123deg) translate3d(819px, 485px, 1000px) scale(1);
            transform: rotate(123deg) translate3d(819px, 485px, 1000px) scale(1);
  }
}

.tri:nth-child(124) {
  border-top: 29px solid #00c4ff;
  border-right: 29px solid transparent;
  border-left: 29px solid transparent;
  margin-left: -14.5px;
  margin-top: -14.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(245deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(245deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim124 10s infinite linear;
          animation: anim124 10s infinite linear;
  -webkit-animation-delay: -6.2s;
          animation-delay: -6.2s;
  opacity: 0;
}

@-webkit-keyframes anim124 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(367.5deg) translate3d(544px, 102px, 1000px) scale(1);
            transform: rotate(367.5deg) translate3d(544px, 102px, 1000px) scale(1);
  }
}

@keyframes anim124 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(367.5deg) translate3d(544px, 102px, 1000px) scale(1);
            transform: rotate(367.5deg) translate3d(544px, 102px, 1000px) scale(1);
  }
}

.tri:nth-child(125) {
  border-top: 35px solid #ff0073;
  border-right: 35px solid transparent;
  border-left: 35px solid transparent;
  margin-left: -17.5px;
  margin-top: -17.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(53deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(53deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim125 10s infinite linear;
          animation: anim125 10s infinite linear;
  -webkit-animation-delay: -6.25s;
          animation-delay: -6.25s;
  opacity: 0;
}

@-webkit-keyframes anim125 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(79.5deg) translate3d(841px, 259px, 1000px) scale(1);
            transform: rotate(79.5deg) translate3d(841px, 259px, 1000px) scale(1);
  }
}

@keyframes anim125 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(79.5deg) translate3d(841px, 259px, 1000px) scale(1);
            transform: rotate(79.5deg) translate3d(841px, 259px, 1000px) scale(1);
  }
}

.tri:nth-child(126) {
  border-top: 3px solid #f2ff00;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  margin-left: -1.5px;
  margin-top: -1.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(234deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(234deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim126 10s infinite linear;
          animation: anim126 10s infinite linear;
  -webkit-animation-delay: -6.3s;
          animation-delay: -6.3s;
  opacity: 0;
}

@-webkit-keyframes anim126 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(351deg) translate3d(37px, 408px, 1000px) scale(1);
            transform: rotate(351deg) translate3d(37px, 408px, 1000px) scale(1);
  }
}

@keyframes anim126 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(351deg) translate3d(37px, 408px, 1000px) scale(1);
            transform: rotate(351deg) translate3d(37px, 408px, 1000px) scale(1);
  }
}

.tri:nth-child(127) {
  border-top: 13px solid #00ff3c;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
  margin-left: -6.5px;
  margin-top: -6.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(274deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(274deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim127 10s infinite linear;
          animation: anim127 10s infinite linear;
  -webkit-animation-delay: -6.35s;
          animation-delay: -6.35s;
  opacity: 0;
}

@-webkit-keyframes anim127 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(411deg) translate3d(912px, 784px, 1000px) scale(1);
            transform: rotate(411deg) translate3d(912px, 784px, 1000px) scale(1);
  }
}

@keyframes anim127 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(411deg) translate3d(912px, 784px, 1000px) scale(1);
            transform: rotate(411deg) translate3d(912px, 784px, 1000px) scale(1);
  }
}

.tri:nth-child(128) {
  border-top: 24px solid #ff6200;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  margin-left: -12px;
  margin-top: -12px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(304deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(304deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim128 10s infinite linear;
          animation: anim128 10s infinite linear;
  -webkit-animation-delay: -6.4s;
          animation-delay: -6.4s;
  opacity: 0;
}

@-webkit-keyframes anim128 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(456deg) translate3d(717px, 708px, 1000px) scale(1);
            transform: rotate(456deg) translate3d(717px, 708px, 1000px) scale(1);
  }
}

@keyframes anim128 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(456deg) translate3d(717px, 708px, 1000px) scale(1);
            transform: rotate(456deg) translate3d(717px, 708px, 1000px) scale(1);
  }
}

.tri:nth-child(129) {
  border-top: 26px solid #ff000d;
  border-right: 26px solid transparent;
  border-left: 26px solid transparent;
  margin-left: -13px;
  margin-top: -13px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(275deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(275deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim129 10s infinite linear;
          animation: anim129 10s infinite linear;
  -webkit-animation-delay: -6.45s;
          animation-delay: -6.45s;
  opacity: 0;
}

@-webkit-keyframes anim129 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(412.5deg) translate3d(241px, 822px, 1000px) scale(1);
            transform: rotate(412.5deg) translate3d(241px, 822px, 1000px) scale(1);
  }
}

@keyframes anim129 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(412.5deg) translate3d(241px, 822px, 1000px) scale(1);
            transform: rotate(412.5deg) translate3d(241px, 822px, 1000px) scale(1);
  }
}

.tri:nth-child(130) {
  border-top: 39px solid #ff0033;
  border-right: 39px solid transparent;
  border-left: 39px solid transparent;
  margin-left: -19.5px;
  margin-top: -19.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(24deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(24deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim130 10s infinite linear;
          animation: anim130 10s infinite linear;
  -webkit-animation-delay: -6.5s;
          animation-delay: -6.5s;
  opacity: 0;
}

@-webkit-keyframes anim130 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(36deg) translate3d(855px, 822px, 1000px) scale(1);
            transform: rotate(36deg) translate3d(855px, 822px, 1000px) scale(1);
  }
}

@keyframes anim130 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(36deg) translate3d(855px, 822px, 1000px) scale(1);
            transform: rotate(36deg) translate3d(855px, 822px, 1000px) scale(1);
  }
}

.tri:nth-child(131) {
  border-top: 17px solid #5900ff;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
  margin-left: -8.5px;
  margin-top: -8.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(107deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(107deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim131 10s infinite linear;
          animation: anim131 10s infinite linear;
  -webkit-animation-delay: -6.55s;
          animation-delay: -6.55s;
  opacity: 0;
}

@-webkit-keyframes anim131 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(160.5deg) translate3d(714px, 302px, 1000px) scale(1);
            transform: rotate(160.5deg) translate3d(714px, 302px, 1000px) scale(1);
  }
}

@keyframes anim131 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(160.5deg) translate3d(714px, 302px, 1000px) scale(1);
            transform: rotate(160.5deg) translate3d(714px, 302px, 1000px) scale(1);
  }
}

.tri:nth-child(132) {
  border-top: 20px solid #ff1e00;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(218deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(218deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim132 10s infinite linear;
          animation: anim132 10s infinite linear;
  -webkit-animation-delay: -6.6s;
          animation-delay: -6.6s;
  opacity: 0;
}

@-webkit-keyframes anim132 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(327deg) translate3d(675px, 93px, 1000px) scale(1);
            transform: rotate(327deg) translate3d(675px, 93px, 1000px) scale(1);
  }
}

@keyframes anim132 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(327deg) translate3d(675px, 93px, 1000px) scale(1);
            transform: rotate(327deg) translate3d(675px, 93px, 1000px) scale(1);
  }
}

.tri:nth-child(133) {
  border-top: 21px solid #fbff00;
  border-right: 21px solid transparent;
  border-left: 21px solid transparent;
  margin-left: -10.5px;
  margin-top: -10.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(243deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(243deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim133 10s infinite linear;
          animation: anim133 10s infinite linear;
  -webkit-animation-delay: -6.65s;
          animation-delay: -6.65s;
  opacity: 0;
}

@-webkit-keyframes anim133 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(364.5deg) translate3d(462px, 380px, 1000px) scale(1);
            transform: rotate(364.5deg) translate3d(462px, 380px, 1000px) scale(1);
  }
}

@keyframes anim133 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(364.5deg) translate3d(462px, 380px, 1000px) scale(1);
            transform: rotate(364.5deg) translate3d(462px, 380px, 1000px) scale(1);
  }
}

.tri:nth-child(134) {
  border-top: 41px solid #1e00ff;
  border-right: 41px solid transparent;
  border-left: 41px solid transparent;
  margin-left: -20.5px;
  margin-top: -20.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(143deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(143deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim134 10s infinite linear;
          animation: anim134 10s infinite linear;
  -webkit-animation-delay: -6.7s;
          animation-delay: -6.7s;
  opacity: 0;
}

@-webkit-keyframes anim134 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(214.5deg) translate3d(568px, 976px, 1000px) scale(1);
            transform: rotate(214.5deg) translate3d(568px, 976px, 1000px) scale(1);
  }
}

@keyframes anim134 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(214.5deg) translate3d(568px, 976px, 1000px) scale(1);
            transform: rotate(214.5deg) translate3d(568px, 976px, 1000px) scale(1);
  }
}

.tri:nth-child(135) {
  border-top: 12px solid #ff00bb;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  margin-left: -6px;
  margin-top: -6px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(31deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(31deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim135 10s infinite linear;
          animation: anim135 10s infinite linear;
  -webkit-animation-delay: -6.75s;
          animation-delay: -6.75s;
  opacity: 0;
}

@-webkit-keyframes anim135 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(46.5deg) translate3d(596px, 106px, 1000px) scale(1);
            transform: rotate(46.5deg) translate3d(596px, 106px, 1000px) scale(1);
  }
}

@keyframes anim135 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(46.5deg) translate3d(596px, 106px, 1000px) scale(1);
            transform: rotate(46.5deg) translate3d(596px, 106px, 1000px) scale(1);
  }
}

.tri:nth-child(136) {
  border-top: 17px solid lime;
  border-right: 17px solid transparent;
  border-left: 17px solid transparent;
  margin-left: -8.5px;
  margin-top: -8.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(302deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(302deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim136 10s infinite linear;
          animation: anim136 10s infinite linear;
  -webkit-animation-delay: -6.8s;
          animation-delay: -6.8s;
  opacity: 0;
}

@-webkit-keyframes anim136 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(453deg) translate3d(330px, 927px, 1000px) scale(1);
            transform: rotate(453deg) translate3d(330px, 927px, 1000px) scale(1);
  }
}

@keyframes anim136 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(453deg) translate3d(330px, 927px, 1000px) scale(1);
            transform: rotate(453deg) translate3d(330px, 927px, 1000px) scale(1);
  }
}

.tri:nth-child(137) {
  border-top: 28px solid #ff3700;
  border-right: 28px solid transparent;
  border-left: 28px solid transparent;
  margin-left: -14px;
  margin-top: -14px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(93deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(93deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim137 10s infinite linear;
          animation: anim137 10s infinite linear;
  -webkit-animation-delay: -6.85s;
          animation-delay: -6.85s;
  opacity: 0;
}

@-webkit-keyframes anim137 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(139.5deg) translate3d(327px, 757px, 1000px) scale(1);
            transform: rotate(139.5deg) translate3d(327px, 757px, 1000px) scale(1);
  }
}

@keyframes anim137 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(139.5deg) translate3d(327px, 757px, 1000px) scale(1);
            transform: rotate(139.5deg) translate3d(327px, 757px, 1000px) scale(1);
  }
}

.tri:nth-child(138) {
  border-top: 44px solid #26ff00;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(148deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(148deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim138 10s infinite linear;
          animation: anim138 10s infinite linear;
  -webkit-animation-delay: -6.9s;
          animation-delay: -6.9s;
  opacity: 0;
}

@-webkit-keyframes anim138 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(222deg) translate3d(103px, 585px, 1000px) scale(1);
            transform: rotate(222deg) translate3d(103px, 585px, 1000px) scale(1);
  }
}

@keyframes anim138 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(222deg) translate3d(103px, 585px, 1000px) scale(1);
            transform: rotate(222deg) translate3d(103px, 585px, 1000px) scale(1);
  }
}

.tri:nth-child(139) {
  border-top: 45px solid #ff00e6;
  border-right: 45px solid transparent;
  border-left: 45px solid transparent;
  margin-left: -22.5px;
  margin-top: -22.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(248deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(248deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim139 10s infinite linear;
          animation: anim139 10s infinite linear;
  -webkit-animation-delay: -6.95s;
          animation-delay: -6.95s;
  opacity: 0;
}

@-webkit-keyframes anim139 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(372deg) translate3d(420px, 961px, 1000px) scale(1);
            transform: rotate(372deg) translate3d(420px, 961px, 1000px) scale(1);
  }
}

@keyframes anim139 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(372deg) translate3d(420px, 961px, 1000px) scale(1);
            transform: rotate(372deg) translate3d(420px, 961px, 1000px) scale(1);
  }
}

.tri:nth-child(140) {
  border-top: 39px solid #0400ff;
  border-right: 39px solid transparent;
  border-left: 39px solid transparent;
  margin-left: -19.5px;
  margin-top: -19.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(302deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(302deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim140 10s infinite linear;
          animation: anim140 10s infinite linear;
  -webkit-animation-delay: -7s;
          animation-delay: -7s;
  opacity: 0;
}

@-webkit-keyframes anim140 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(453deg) translate3d(374px, 357px, 1000px) scale(1);
            transform: rotate(453deg) translate3d(374px, 357px, 1000px) scale(1);
  }
}

@keyframes anim140 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(453deg) translate3d(374px, 357px, 1000px) scale(1);
            transform: rotate(453deg) translate3d(374px, 357px, 1000px) scale(1);
  }
}

.tri:nth-child(141) {
  border-top: 47px solid #ff00fb;
  border-right: 47px solid transparent;
  border-left: 47px solid transparent;
  margin-left: -23.5px;
  margin-top: -23.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(30deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(30deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim141 10s infinite linear;
          animation: anim141 10s infinite linear;
  -webkit-animation-delay: -7.05s;
          animation-delay: -7.05s;
  opacity: 0;
}

@-webkit-keyframes anim141 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(45deg) translate3d(285px, 633px, 1000px) scale(1);
            transform: rotate(45deg) translate3d(285px, 633px, 1000px) scale(1);
  }
}

@keyframes anim141 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(45deg) translate3d(285px, 633px, 1000px) scale(1);
            transform: rotate(45deg) translate3d(285px, 633px, 1000px) scale(1);
  }
}

.tri:nth-child(142) {
  border-top: 9px solid #2f00ff;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(263deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(263deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim142 10s infinite linear;
          animation: anim142 10s infinite linear;
  -webkit-animation-delay: -7.1s;
          animation-delay: -7.1s;
  opacity: 0;
}

@-webkit-keyframes anim142 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(394.5deg) translate3d(898px, 470px, 1000px) scale(1);
            transform: rotate(394.5deg) translate3d(898px, 470px, 1000px) scale(1);
  }
}

@keyframes anim142 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(394.5deg) translate3d(898px, 470px, 1000px) scale(1);
            transform: rotate(394.5deg) translate3d(898px, 470px, 1000px) scale(1);
  }
}

.tri:nth-child(143) {
  border-top: 49px solid #2200ff;
  border-right: 49px solid transparent;
  border-left: 49px solid transparent;
  margin-left: -24.5px;
  margin-top: -24.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(15deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(15deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim143 10s infinite linear;
          animation: anim143 10s infinite linear;
  -webkit-animation-delay: -7.15s;
          animation-delay: -7.15s;
  opacity: 0;
}

@-webkit-keyframes anim143 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(22.5deg) translate3d(866px, 41px, 1000px) scale(1);
            transform: rotate(22.5deg) translate3d(866px, 41px, 1000px) scale(1);
  }
}

@keyframes anim143 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(22.5deg) translate3d(866px, 41px, 1000px) scale(1);
            transform: rotate(22.5deg) translate3d(866px, 41px, 1000px) scale(1);
  }
}

.tri:nth-child(144) {
  border-top: 34px solid #ff0033;
  border-right: 34px solid transparent;
  border-left: 34px solid transparent;
  margin-left: -17px;
  margin-top: -17px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(273deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(273deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim144 10s infinite linear;
          animation: anim144 10s infinite linear;
  -webkit-animation-delay: -7.2s;
          animation-delay: -7.2s;
  opacity: 0;
}

@-webkit-keyframes anim144 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(409.5deg) translate3d(690px, 140px, 1000px) scale(1);
            transform: rotate(409.5deg) translate3d(690px, 140px, 1000px) scale(1);
  }
}

@keyframes anim144 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(409.5deg) translate3d(690px, 140px, 1000px) scale(1);
            transform: rotate(409.5deg) translate3d(690px, 140px, 1000px) scale(1);
  }
}

.tri:nth-child(145) {
  border-top: 5px solid #66ff00;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  margin-left: -2.5px;
  margin-top: -2.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(86deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(86deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim145 10s infinite linear;
          animation: anim145 10s infinite linear;
  -webkit-animation-delay: -7.25s;
          animation-delay: -7.25s;
  opacity: 0;
}

@-webkit-keyframes anim145 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(129deg) translate3d(833px, 355px, 1000px) scale(1);
            transform: rotate(129deg) translate3d(833px, 355px, 1000px) scale(1);
  }
}

@keyframes anim145 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(129deg) translate3d(833px, 355px, 1000px) scale(1);
            transform: rotate(129deg) translate3d(833px, 355px, 1000px) scale(1);
  }
}

.tri:nth-child(146) {
  border-top: 28px solid #ff9900;
  border-right: 28px solid transparent;
  border-left: 28px solid transparent;
  margin-left: -14px;
  margin-top: -14px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(32deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(32deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim146 10s infinite linear;
          animation: anim146 10s infinite linear;
  -webkit-animation-delay: -7.3s;
          animation-delay: -7.3s;
  opacity: 0;
}

@-webkit-keyframes anim146 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(48deg) translate3d(241px, 657px, 1000px) scale(1);
            transform: rotate(48deg) translate3d(241px, 657px, 1000px) scale(1);
  }
}

@keyframes anim146 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(48deg) translate3d(241px, 657px, 1000px) scale(1);
            transform: rotate(48deg) translate3d(241px, 657px, 1000px) scale(1);
  }
}

.tri:nth-child(147) {
  border-top: 33px solid #aeff00;
  border-right: 33px solid transparent;
  border-left: 33px solid transparent;
  margin-left: -16.5px;
  margin-top: -16.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(208deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(208deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim147 10s infinite linear;
          animation: anim147 10s infinite linear;
  -webkit-animation-delay: -7.35s;
          animation-delay: -7.35s;
  opacity: 0;
}

@-webkit-keyframes anim147 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(312deg) translate3d(9px, 291px, 1000px) scale(1);
            transform: rotate(312deg) translate3d(9px, 291px, 1000px) scale(1);
  }
}

@keyframes anim147 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(312deg) translate3d(9px, 291px, 1000px) scale(1);
            transform: rotate(312deg) translate3d(9px, 291px, 1000px) scale(1);
  }
}

.tri:nth-child(148) {
  border-top: 11px solid #ffaa00;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  margin-left: -5.5px;
  margin-top: -5.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(162deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(162deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim148 10s infinite linear;
          animation: anim148 10s infinite linear;
  -webkit-animation-delay: -7.4s;
          animation-delay: -7.4s;
  opacity: 0;
}

@-webkit-keyframes anim148 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(243deg) translate3d(573px, 630px, 1000px) scale(1);
            transform: rotate(243deg) translate3d(573px, 630px, 1000px) scale(1);
  }
}

@keyframes anim148 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(243deg) translate3d(573px, 630px, 1000px) scale(1);
            transform: rotate(243deg) translate3d(573px, 630px, 1000px) scale(1);
  }
}

.tri:nth-child(149) {
  border-top: 21px solid #73ff00;
  border-right: 21px solid transparent;
  border-left: 21px solid transparent;
  margin-left: -10.5px;
  margin-top: -10.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(261deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(261deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim149 10s infinite linear;
          animation: anim149 10s infinite linear;
  -webkit-animation-delay: -7.45s;
          animation-delay: -7.45s;
  opacity: 0;
}

@-webkit-keyframes anim149 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(391.5deg) translate3d(125px, 110px, 1000px) scale(1);
            transform: rotate(391.5deg) translate3d(125px, 110px, 1000px) scale(1);
  }
}

@keyframes anim149 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(391.5deg) translate3d(125px, 110px, 1000px) scale(1);
            transform: rotate(391.5deg) translate3d(125px, 110px, 1000px) scale(1);
  }
}

.tri:nth-child(150) {
  border-top: 6px solid #00ff91;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(187deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(187deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim150 10s infinite linear;
          animation: anim150 10s infinite linear;
  -webkit-animation-delay: -7.5s;
          animation-delay: -7.5s;
  opacity: 0;
}

@-webkit-keyframes anim150 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(280.5deg) translate3d(740px, 332px, 1000px) scale(1);
            transform: rotate(280.5deg) translate3d(740px, 332px, 1000px) scale(1);
  }
}

@keyframes anim150 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(280.5deg) translate3d(740px, 332px, 1000px) scale(1);
            transform: rotate(280.5deg) translate3d(740px, 332px, 1000px) scale(1);
  }
}

.tri:nth-child(151) {
  border-top: 29px solid yellow;
  border-right: 29px solid transparent;
  border-left: 29px solid transparent;
  margin-left: -14.5px;
  margin-top: -14.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(227deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(227deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim151 10s infinite linear;
          animation: anim151 10s infinite linear;
  -webkit-animation-delay: -7.55s;
          animation-delay: -7.55s;
  opacity: 0;
}

@-webkit-keyframes anim151 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(340.5deg) translate3d(582px, 392px, 1000px) scale(1);
            transform: rotate(340.5deg) translate3d(582px, 392px, 1000px) scale(1);
  }
}

@keyframes anim151 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(340.5deg) translate3d(582px, 392px, 1000px) scale(1);
            transform: rotate(340.5deg) translate3d(582px, 392px, 1000px) scale(1);
  }
}

.tri:nth-child(152) {
  border-top: 7px solid #00ffc8;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -3.5px;
  margin-top: -3.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(272deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(272deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim152 10s infinite linear;
          animation: anim152 10s infinite linear;
  -webkit-animation-delay: -7.6s;
          animation-delay: -7.6s;
  opacity: 0;
}

@-webkit-keyframes anim152 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(408deg) translate3d(170px, 294px, 1000px) scale(1);
            transform: rotate(408deg) translate3d(170px, 294px, 1000px) scale(1);
  }
}

@keyframes anim152 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(408deg) translate3d(170px, 294px, 1000px) scale(1);
            transform: rotate(408deg) translate3d(170px, 294px, 1000px) scale(1);
  }
}

.tri:nth-child(153) {
  border-top: 18px solid #ffc800;
  border-right: 18px solid transparent;
  border-left: 18px solid transparent;
  margin-left: -9px;
  margin-top: -9px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(353deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(353deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim153 10s infinite linear;
          animation: anim153 10s infinite linear;
  -webkit-animation-delay: -7.65s;
          animation-delay: -7.65s;
  opacity: 0;
}

@-webkit-keyframes anim153 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(529.5deg) translate3d(8px, 937px, 1000px) scale(1);
            transform: rotate(529.5deg) translate3d(8px, 937px, 1000px) scale(1);
  }
}

@keyframes anim153 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(529.5deg) translate3d(8px, 937px, 1000px) scale(1);
            transform: rotate(529.5deg) translate3d(8px, 937px, 1000px) scale(1);
  }
}

.tri:nth-child(154) {
  border-top: 25px solid #99ff00;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
  margin-left: -12.5px;
  margin-top: -12.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(14deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(14deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim154 10s infinite linear;
          animation: anim154 10s infinite linear;
  -webkit-animation-delay: -7.7s;
          animation-delay: -7.7s;
  opacity: 0;
}

@-webkit-keyframes anim154 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(21deg) translate3d(312px, 343px, 1000px) scale(1);
            transform: rotate(21deg) translate3d(312px, 343px, 1000px) scale(1);
  }
}

@keyframes anim154 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(21deg) translate3d(312px, 343px, 1000px) scale(1);
            transform: rotate(21deg) translate3d(312px, 343px, 1000px) scale(1);
  }
}

.tri:nth-child(155) {
  border-top: 15px solid #ff00aa;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  margin-left: -7.5px;
  margin-top: -7.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(128deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(128deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim155 10s infinite linear;
          animation: anim155 10s infinite linear;
  -webkit-animation-delay: -7.75s;
          animation-delay: -7.75s;
  opacity: 0;
}

@-webkit-keyframes anim155 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(192deg) translate3d(70px, 641px, 1000px) scale(1);
            transform: rotate(192deg) translate3d(70px, 641px, 1000px) scale(1);
  }
}

@keyframes anim155 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(192deg) translate3d(70px, 641px, 1000px) scale(1);
            transform: rotate(192deg) translate3d(70px, 641px, 1000px) scale(1);
  }
}

.tri:nth-child(156) {
  border-top: 9px solid #006aff;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  margin-left: -4.5px;
  margin-top: -4.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(29deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(29deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim156 10s infinite linear;
          animation: anim156 10s infinite linear;
  -webkit-animation-delay: -7.8s;
          animation-delay: -7.8s;
  opacity: 0;
}

@-webkit-keyframes anim156 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(43.5deg) translate3d(580px, 467px, 1000px) scale(1);
            transform: rotate(43.5deg) translate3d(580px, 467px, 1000px) scale(1);
  }
}

@keyframes anim156 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(43.5deg) translate3d(580px, 467px, 1000px) scale(1);
            transform: rotate(43.5deg) translate3d(580px, 467px, 1000px) scale(1);
  }
}

.tri:nth-child(157) {
  border-top: 13px solid #ff0091;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
  margin-left: -6.5px;
  margin-top: -6.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(312deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(312deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim157 10s infinite linear;
          animation: anim157 10s infinite linear;
  -webkit-animation-delay: -7.85s;
          animation-delay: -7.85s;
  opacity: 0;
}

@-webkit-keyframes anim157 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(468deg) translate3d(994px, 222px, 1000px) scale(1);
            transform: rotate(468deg) translate3d(994px, 222px, 1000px) scale(1);
  }
}

@keyframes anim157 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(468deg) translate3d(994px, 222px, 1000px) scale(1);
            transform: rotate(468deg) translate3d(994px, 222px, 1000px) scale(1);
  }
}

.tri:nth-child(158) {
  border-top: 48px solid #002fff;
  border-right: 48px solid transparent;
  border-left: 48px solid transparent;
  margin-left: -24px;
  margin-top: -24px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(22deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(22deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim158 10s infinite linear;
          animation: anim158 10s infinite linear;
  -webkit-animation-delay: -7.9s;
          animation-delay: -7.9s;
  opacity: 0;
}

@-webkit-keyframes anim158 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(33deg) translate3d(749px, 132px, 1000px) scale(1);
            transform: rotate(33deg) translate3d(749px, 132px, 1000px) scale(1);
  }
}

@keyframes anim158 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(33deg) translate3d(749px, 132px, 1000px) scale(1);
            transform: rotate(33deg) translate3d(749px, 132px, 1000px) scale(1);
  }
}

.tri:nth-child(159) {
  border-top: 1px solid #ff3300;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-left: -0.5px;
  margin-top: -0.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(19deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(19deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim159 10s infinite linear;
          animation: anim159 10s infinite linear;
  -webkit-animation-delay: -7.95s;
          animation-delay: -7.95s;
  opacity: 0;
}

@-webkit-keyframes anim159 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(28.5deg) translate3d(520px, 735px, 1000px) scale(1);
            transform: rotate(28.5deg) translate3d(520px, 735px, 1000px) scale(1);
  }
}

@keyframes anim159 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(28.5deg) translate3d(520px, 735px, 1000px) scale(1);
            transform: rotate(28.5deg) translate3d(520px, 735px, 1000px) scale(1);
  }
}

.tri:nth-child(160) {
  border-top: 23px solid #fb00ff;
  border-right: 23px solid transparent;
  border-left: 23px solid transparent;
  margin-left: -11.5px;
  margin-top: -11.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(56deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(56deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim160 10s infinite linear;
          animation: anim160 10s infinite linear;
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
  opacity: 0;
}

@-webkit-keyframes anim160 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(84deg) translate3d(951px, 136px, 1000px) scale(1);
            transform: rotate(84deg) translate3d(951px, 136px, 1000px) scale(1);
  }
}

@keyframes anim160 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(84deg) translate3d(951px, 136px, 1000px) scale(1);
            transform: rotate(84deg) translate3d(951px, 136px, 1000px) scale(1);
  }
}

.tri:nth-child(161) {
  border-top: 11px solid #00ffee;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  margin-left: -5.5px;
  margin-top: -5.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(160deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(160deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim161 10s infinite linear;
          animation: anim161 10s infinite linear;
  -webkit-animation-delay: -8.05s;
          animation-delay: -8.05s;
  opacity: 0;
}

@-webkit-keyframes anim161 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(240deg) translate3d(778px, 154px, 1000px) scale(1);
            transform: rotate(240deg) translate3d(778px, 154px, 1000px) scale(1);
  }
}

@keyframes anim161 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(240deg) translate3d(778px, 154px, 1000px) scale(1);
            transform: rotate(240deg) translate3d(778px, 154px, 1000px) scale(1);
  }
}

.tri:nth-child(162) {
  border-top: 19px solid #00ff7b;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(184deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(184deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim162 10s infinite linear;
          animation: anim162 10s infinite linear;
  -webkit-animation-delay: -8.1s;
          animation-delay: -8.1s;
  opacity: 0;
}

@-webkit-keyframes anim162 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(276deg) translate3d(353px, 358px, 1000px) scale(1);
            transform: rotate(276deg) translate3d(353px, 358px, 1000px) scale(1);
  }
}

@keyframes anim162 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(276deg) translate3d(353px, 358px, 1000px) scale(1);
            transform: rotate(276deg) translate3d(353px, 358px, 1000px) scale(1);
  }
}

.tri:nth-child(163) {
  border-top: 8px solid #7b00ff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  margin-left: -4px;
  margin-top: -4px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(148deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(148deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim163 10s infinite linear;
          animation: anim163 10s infinite linear;
  -webkit-animation-delay: -8.15s;
          animation-delay: -8.15s;
  opacity: 0;
}

@-webkit-keyframes anim163 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(222deg) translate3d(660px, 909px, 1000px) scale(1);
            transform: rotate(222deg) translate3d(660px, 909px, 1000px) scale(1);
  }
}

@keyframes anim163 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(222deg) translate3d(660px, 909px, 1000px) scale(1);
            transform: rotate(222deg) translate3d(660px, 909px, 1000px) scale(1);
  }
}

.tri:nth-child(164) {
  border-top: 3px solid #ffd000;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  margin-left: -1.5px;
  margin-top: -1.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(288deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(288deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim164 10s infinite linear;
          animation: anim164 10s infinite linear;
  -webkit-animation-delay: -8.2s;
          animation-delay: -8.2s;
  opacity: 0;
}

@-webkit-keyframes anim164 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(432deg) translate3d(896px, 20px, 1000px) scale(1);
            transform: rotate(432deg) translate3d(896px, 20px, 1000px) scale(1);
  }
}

@keyframes anim164 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(432deg) translate3d(896px, 20px, 1000px) scale(1);
            transform: rotate(432deg) translate3d(896px, 20px, 1000px) scale(1);
  }
}

.tri:nth-child(165) {
  border-top: 13px solid #002fff;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
  margin-left: -6.5px;
  margin-top: -6.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(184deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(184deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim165 10s infinite linear;
          animation: anim165 10s infinite linear;
  -webkit-animation-delay: -8.25s;
          animation-delay: -8.25s;
  opacity: 0;
}

@-webkit-keyframes anim165 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(276deg) translate3d(924px, 548px, 1000px) scale(1);
            transform: rotate(276deg) translate3d(924px, 548px, 1000px) scale(1);
  }
}

@keyframes anim165 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(276deg) translate3d(924px, 548px, 1000px) scale(1);
            transform: rotate(276deg) translate3d(924px, 548px, 1000px) scale(1);
  }
}

.tri:nth-child(166) {
  border-top: 10px solid #3700ff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  margin-left: -5px;
  margin-top: -5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(290deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(290deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim166 10s infinite linear;
          animation: anim166 10s infinite linear;
  -webkit-animation-delay: -8.3s;
          animation-delay: -8.3s;
  opacity: 0;
}

@-webkit-keyframes anim166 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(435deg) translate3d(638px, 353px, 1000px) scale(1);
            transform: rotate(435deg) translate3d(638px, 353px, 1000px) scale(1);
  }
}

@keyframes anim166 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(435deg) translate3d(638px, 353px, 1000px) scale(1);
            transform: rotate(435deg) translate3d(638px, 353px, 1000px) scale(1);
  }
}

.tri:nth-child(167) {
  border-top: 22px solid #ff9d00;
  border-right: 22px solid transparent;
  border-left: 22px solid transparent;
  margin-left: -11px;
  margin-top: -11px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(128deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(128deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim167 10s infinite linear;
          animation: anim167 10s infinite linear;
  -webkit-animation-delay: -8.35s;
          animation-delay: -8.35s;
  opacity: 0;
}

@-webkit-keyframes anim167 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(192deg) translate3d(647px, 771px, 1000px) scale(1);
            transform: rotate(192deg) translate3d(647px, 771px, 1000px) scale(1);
  }
}

@keyframes anim167 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(192deg) translate3d(647px, 771px, 1000px) scale(1);
            transform: rotate(192deg) translate3d(647px, 771px, 1000px) scale(1);
  }
}

.tri:nth-child(168) {
  border-top: 44px solid #ff0088;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(172deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(172deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim168 10s infinite linear;
          animation: anim168 10s infinite linear;
  -webkit-animation-delay: -8.4s;
          animation-delay: -8.4s;
  opacity: 0;
}

@-webkit-keyframes anim168 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(258deg) translate3d(641px, 962px, 1000px) scale(1);
            transform: rotate(258deg) translate3d(641px, 962px, 1000px) scale(1);
  }
}

@keyframes anim168 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(258deg) translate3d(641px, 962px, 1000px) scale(1);
            transform: rotate(258deg) translate3d(641px, 962px, 1000px) scale(1);
  }
}

.tri:nth-child(169) {
  border-top: 6px solid #00eaff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(339deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(339deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim169 10s infinite linear;
          animation: anim169 10s infinite linear;
  -webkit-animation-delay: -8.45s;
          animation-delay: -8.45s;
  opacity: 0;
}

@-webkit-keyframes anim169 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(508.5deg) translate3d(81px, 668px, 1000px) scale(1);
            transform: rotate(508.5deg) translate3d(81px, 668px, 1000px) scale(1);
  }
}

@keyframes anim169 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(508.5deg) translate3d(81px, 668px, 1000px) scale(1);
            transform: rotate(508.5deg) translate3d(81px, 668px, 1000px) scale(1);
  }
}

.tri:nth-child(170) {
  border-top: 20px solid #4dff00;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(186deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(186deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim170 10s infinite linear;
          animation: anim170 10s infinite linear;
  -webkit-animation-delay: -8.5s;
          animation-delay: -8.5s;
  opacity: 0;
}

@-webkit-keyframes anim170 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(279deg) translate3d(233px, 726px, 1000px) scale(1);
            transform: rotate(279deg) translate3d(233px, 726px, 1000px) scale(1);
  }
}

@keyframes anim170 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(279deg) translate3d(233px, 726px, 1000px) scale(1);
            transform: rotate(279deg) translate3d(233px, 726px, 1000px) scale(1);
  }
}

.tri:nth-child(171) {
  border-top: 16px solid #0059ff;
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
  margin-left: -8px;
  margin-top: -8px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(289deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(289deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim171 10s infinite linear;
          animation: anim171 10s infinite linear;
  -webkit-animation-delay: -8.55s;
          animation-delay: -8.55s;
  opacity: 0;
}

@-webkit-keyframes anim171 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(433.5deg) translate3d(247px, 663px, 1000px) scale(1);
            transform: rotate(433.5deg) translate3d(247px, 663px, 1000px) scale(1);
  }
}

@keyframes anim171 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(433.5deg) translate3d(247px, 663px, 1000px) scale(1);
            transform: rotate(433.5deg) translate3d(247px, 663px, 1000px) scale(1);
  }
}

.tri:nth-child(172) {
  border-top: 26px solid #ff0033;
  border-right: 26px solid transparent;
  border-left: 26px solid transparent;
  margin-left: -13px;
  margin-top: -13px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(30deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(30deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim172 10s infinite linear;
          animation: anim172 10s infinite linear;
  -webkit-animation-delay: -8.6s;
          animation-delay: -8.6s;
  opacity: 0;
}

@-webkit-keyframes anim172 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(45deg) translate3d(503px, 139px, 1000px) scale(1);
            transform: rotate(45deg) translate3d(503px, 139px, 1000px) scale(1);
  }
}

@keyframes anim172 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(45deg) translate3d(503px, 139px, 1000px) scale(1);
            transform: rotate(45deg) translate3d(503px, 139px, 1000px) scale(1);
  }
}

.tri:nth-child(173) {
  border-top: 38px solid #00ff40;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(298deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(298deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim173 10s infinite linear;
          animation: anim173 10s infinite linear;
  -webkit-animation-delay: -8.65s;
          animation-delay: -8.65s;
  opacity: 0;
}

@-webkit-keyframes anim173 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(447deg) translate3d(416px, 356px, 1000px) scale(1);
            transform: rotate(447deg) translate3d(416px, 356px, 1000px) scale(1);
  }
}

@keyframes anim173 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(447deg) translate3d(416px, 356px, 1000px) scale(1);
            transform: rotate(447deg) translate3d(416px, 356px, 1000px) scale(1);
  }
}

.tri:nth-child(174) {
  border-top: 24px solid #00ddff;
  border-right: 24px solid transparent;
  border-left: 24px solid transparent;
  margin-left: -12px;
  margin-top: -12px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(269deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(269deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim174 10s infinite linear;
          animation: anim174 10s infinite linear;
  -webkit-animation-delay: -8.7s;
          animation-delay: -8.7s;
  opacity: 0;
}

@-webkit-keyframes anim174 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(403.5deg) translate3d(851px, 904px, 1000px) scale(1);
            transform: rotate(403.5deg) translate3d(851px, 904px, 1000px) scale(1);
  }
}

@keyframes anim174 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(403.5deg) translate3d(851px, 904px, 1000px) scale(1);
            transform: rotate(403.5deg) translate3d(851px, 904px, 1000px) scale(1);
  }
}

.tri:nth-child(175) {
  border-top: 7px solid #bbff00;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -3.5px;
  margin-top: -3.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(130deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(130deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim175 10s infinite linear;
          animation: anim175 10s infinite linear;
  -webkit-animation-delay: -8.75s;
          animation-delay: -8.75s;
  opacity: 0;
}

@-webkit-keyframes anim175 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(195deg) translate3d(836px, 78px, 1000px) scale(1);
            transform: rotate(195deg) translate3d(836px, 78px, 1000px) scale(1);
  }
}

@keyframes anim175 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(195deg) translate3d(836px, 78px, 1000px) scale(1);
            transform: rotate(195deg) translate3d(836px, 78px, 1000px) scale(1);
  }
}

.tri:nth-child(176) {
  border-top: 30px solid #00ff62;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  margin-left: -15px;
  margin-top: -15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(143deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(143deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim176 10s infinite linear;
          animation: anim176 10s infinite linear;
  -webkit-animation-delay: -8.8s;
          animation-delay: -8.8s;
  opacity: 0;
}

@-webkit-keyframes anim176 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(214.5deg) translate3d(512px, 800px, 1000px) scale(1);
            transform: rotate(214.5deg) translate3d(512px, 800px, 1000px) scale(1);
  }
}

@keyframes anim176 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(214.5deg) translate3d(512px, 800px, 1000px) scale(1);
            transform: rotate(214.5deg) translate3d(512px, 800px, 1000px) scale(1);
  }
}

.tri:nth-child(177) {
  border-top: 36px solid #6a00ff;
  border-right: 36px solid transparent;
  border-left: 36px solid transparent;
  margin-left: -18px;
  margin-top: -18px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(239deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(239deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim177 10s infinite linear;
          animation: anim177 10s infinite linear;
  -webkit-animation-delay: -8.85s;
          animation-delay: -8.85s;
  opacity: 0;
}

@-webkit-keyframes anim177 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(358.5deg) translate3d(972px, 9px, 1000px) scale(1);
            transform: rotate(358.5deg) translate3d(972px, 9px, 1000px) scale(1);
  }
}

@keyframes anim177 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(358.5deg) translate3d(972px, 9px, 1000px) scale(1);
            transform: rotate(358.5deg) translate3d(972px, 9px, 1000px) scale(1);
  }
}

.tri:nth-child(178) {
  border-top: 30px solid #00f2ff;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  margin-left: -15px;
  margin-top: -15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(16deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(16deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim178 10s infinite linear;
          animation: anim178 10s infinite linear;
  -webkit-animation-delay: -8.9s;
          animation-delay: -8.9s;
  opacity: 0;
}

@-webkit-keyframes anim178 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(24deg) translate3d(535px, 493px, 1000px) scale(1);
            transform: rotate(24deg) translate3d(535px, 493px, 1000px) scale(1);
  }
}

@keyframes anim178 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(24deg) translate3d(535px, 493px, 1000px) scale(1);
            transform: rotate(24deg) translate3d(535px, 493px, 1000px) scale(1);
  }
}

.tri:nth-child(179) {
  border-top: 32px solid #00b3ff;
  border-right: 32px solid transparent;
  border-left: 32px solid transparent;
  margin-left: -16px;
  margin-top: -16px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(270deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(270deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim179 10s infinite linear;
          animation: anim179 10s infinite linear;
  -webkit-animation-delay: -8.95s;
          animation-delay: -8.95s;
  opacity: 0;
}

@-webkit-keyframes anim179 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(405deg) translate3d(96px, 192px, 1000px) scale(1);
            transform: rotate(405deg) translate3d(96px, 192px, 1000px) scale(1);
  }
}

@keyframes anim179 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(405deg) translate3d(96px, 192px, 1000px) scale(1);
            transform: rotate(405deg) translate3d(96px, 192px, 1000px) scale(1);
  }
}

.tri:nth-child(180) {
  border-top: 20px solid #0033ff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(195deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(195deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim180 10s infinite linear;
          animation: anim180 10s infinite linear;
  -webkit-animation-delay: -9s;
          animation-delay: -9s;
  opacity: 0;
}

@-webkit-keyframes anim180 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(292.5deg) translate3d(185px, 232px, 1000px) scale(1);
            transform: rotate(292.5deg) translate3d(185px, 232px, 1000px) scale(1);
  }
}

@keyframes anim180 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(292.5deg) translate3d(185px, 232px, 1000px) scale(1);
            transform: rotate(292.5deg) translate3d(185px, 232px, 1000px) scale(1);
  }
}

.tri:nth-child(181) {
  border-top: 30px solid #91ff00;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
  margin-left: -15px;
  margin-top: -15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(142deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(142deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim181 10s infinite linear;
          animation: anim181 10s infinite linear;
  -webkit-animation-delay: -9.05s;
          animation-delay: -9.05s;
  opacity: 0;
}

@-webkit-keyframes anim181 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(213deg) translate3d(258px, 150px, 1000px) scale(1);
            transform: rotate(213deg) translate3d(258px, 150px, 1000px) scale(1);
  }
}

@keyframes anim181 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(213deg) translate3d(258px, 150px, 1000px) scale(1);
            transform: rotate(213deg) translate3d(258px, 150px, 1000px) scale(1);
  }
}

.tri:nth-child(182) {
  border-top: 2px solid #ff5100;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  margin-left: -1px;
  margin-top: -1px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(51deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(51deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim182 10s infinite linear;
          animation: anim182 10s infinite linear;
  -webkit-animation-delay: -9.1s;
          animation-delay: -9.1s;
  opacity: 0;
}

@-webkit-keyframes anim182 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(76.5deg) translate3d(612px, 207px, 1000px) scale(1);
            transform: rotate(76.5deg) translate3d(612px, 207px, 1000px) scale(1);
  }
}

@keyframes anim182 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(76.5deg) translate3d(612px, 207px, 1000px) scale(1);
            transform: rotate(76.5deg) translate3d(612px, 207px, 1000px) scale(1);
  }
}

.tri:nth-child(183) {
  border-top: 12px solid #0066ff;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  margin-left: -6px;
  margin-top: -6px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(353deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(353deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim183 10s infinite linear;
          animation: anim183 10s infinite linear;
  -webkit-animation-delay: -9.15s;
          animation-delay: -9.15s;
  opacity: 0;
}

@-webkit-keyframes anim183 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(529.5deg) translate3d(314px, 205px, 1000px) scale(1);
            transform: rotate(529.5deg) translate3d(314px, 205px, 1000px) scale(1);
  }
}

@keyframes anim183 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(529.5deg) translate3d(314px, 205px, 1000px) scale(1);
            transform: rotate(529.5deg) translate3d(314px, 205px, 1000px) scale(1);
  }
}

.tri:nth-child(184) {
  border-top: 1px solid #00c4ff;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  margin-left: -0.5px;
  margin-top: -0.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(36deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(36deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim184 10s infinite linear;
          animation: anim184 10s infinite linear;
  -webkit-animation-delay: -9.2s;
          animation-delay: -9.2s;
  opacity: 0;
}

@-webkit-keyframes anim184 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(54deg) translate3d(977px, 83px, 1000px) scale(1);
            transform: rotate(54deg) translate3d(977px, 83px, 1000px) scale(1);
  }
}

@keyframes anim184 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(54deg) translate3d(977px, 83px, 1000px) scale(1);
            transform: rotate(54deg) translate3d(977px, 83px, 1000px) scale(1);
  }
}

.tri:nth-child(185) {
  border-top: 20px solid #ff00fb;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(242deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(242deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim185 10s infinite linear;
          animation: anim185 10s infinite linear;
  -webkit-animation-delay: -9.25s;
          animation-delay: -9.25s;
  opacity: 0;
}

@-webkit-keyframes anim185 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(363deg) translate3d(41px, 188px, 1000px) scale(1);
            transform: rotate(363deg) translate3d(41px, 188px, 1000px) scale(1);
  }
}

@keyframes anim185 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(363deg) translate3d(41px, 188px, 1000px) scale(1);
            transform: rotate(363deg) translate3d(41px, 188px, 1000px) scale(1);
  }
}

.tri:nth-child(186) {
  border-top: 34px solid #ff009d;
  border-right: 34px solid transparent;
  border-left: 34px solid transparent;
  margin-left: -17px;
  margin-top: -17px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(270deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(270deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim186 10s infinite linear;
          animation: anim186 10s infinite linear;
  -webkit-animation-delay: -9.3s;
          animation-delay: -9.3s;
  opacity: 0;
}

@-webkit-keyframes anim186 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(405deg) translate3d(927px, 546px, 1000px) scale(1);
            transform: rotate(405deg) translate3d(927px, 546px, 1000px) scale(1);
  }
}

@keyframes anim186 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(405deg) translate3d(927px, 546px, 1000px) scale(1);
            transform: rotate(405deg) translate3d(927px, 546px, 1000px) scale(1);
  }
}

.tri:nth-child(187) {
  border-top: 41px solid #ff00d9;
  border-right: 41px solid transparent;
  border-left: 41px solid transparent;
  margin-left: -20.5px;
  margin-top: -20.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(42deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(42deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim187 10s infinite linear;
          animation: anim187 10s infinite linear;
  -webkit-animation-delay: -9.35s;
          animation-delay: -9.35s;
  opacity: 0;
}

@-webkit-keyframes anim187 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(63deg) translate3d(189px, 435px, 1000px) scale(1);
            transform: rotate(63deg) translate3d(189px, 435px, 1000px) scale(1);
  }
}

@keyframes anim187 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(63deg) translate3d(189px, 435px, 1000px) scale(1);
            transform: rotate(63deg) translate3d(189px, 435px, 1000px) scale(1);
  }
}

.tri:nth-child(188) {
  border-top: 43px solid #ff8400;
  border-right: 43px solid transparent;
  border-left: 43px solid transparent;
  margin-left: -21.5px;
  margin-top: -21.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(281deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(281deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim188 10s infinite linear;
          animation: anim188 10s infinite linear;
  -webkit-animation-delay: -9.4s;
          animation-delay: -9.4s;
  opacity: 0;
}

@-webkit-keyframes anim188 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(421.5deg) translate3d(336px, 364px, 1000px) scale(1);
            transform: rotate(421.5deg) translate3d(336px, 364px, 1000px) scale(1);
  }
}

@keyframes anim188 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(421.5deg) translate3d(336px, 364px, 1000px) scale(1);
            transform: rotate(421.5deg) translate3d(336px, 364px, 1000px) scale(1);
  }
}

.tri:nth-child(189) {
  border-top: 33px solid #22ff00;
  border-right: 33px solid transparent;
  border-left: 33px solid transparent;
  margin-left: -16.5px;
  margin-top: -16.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(36deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(36deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim189 10s infinite linear;
          animation: anim189 10s infinite linear;
  -webkit-animation-delay: -9.45s;
          animation-delay: -9.45s;
  opacity: 0;
}

@-webkit-keyframes anim189 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(54deg) translate3d(621px, 973px, 1000px) scale(1);
            transform: rotate(54deg) translate3d(621px, 973px, 1000px) scale(1);
  }
}

@keyframes anim189 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(54deg) translate3d(621px, 973px, 1000px) scale(1);
            transform: rotate(54deg) translate3d(621px, 973px, 1000px) scale(1);
  }
}

.tri:nth-child(190) {
  border-top: 6px solid #48ff00;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(299deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(299deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim190 10s infinite linear;
          animation: anim190 10s infinite linear;
  -webkit-animation-delay: -9.5s;
          animation-delay: -9.5s;
  opacity: 0;
}

@-webkit-keyframes anim190 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(448.5deg) translate3d(18px, 216px, 1000px) scale(1);
            transform: rotate(448.5deg) translate3d(18px, 216px, 1000px) scale(1);
  }
}

@keyframes anim190 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(448.5deg) translate3d(18px, 216px, 1000px) scale(1);
            transform: rotate(448.5deg) translate3d(18px, 216px, 1000px) scale(1);
  }
}

.tri:nth-child(191) {
  border-top: 28px solid #ffb700;
  border-right: 28px solid transparent;
  border-left: 28px solid transparent;
  margin-left: -14px;
  margin-top: -14px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(98deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(98deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim191 10s infinite linear;
          animation: anim191 10s infinite linear;
  -webkit-animation-delay: -9.55s;
          animation-delay: -9.55s;
  opacity: 0;
}

@-webkit-keyframes anim191 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(147deg) translate3d(596px, 630px, 1000px) scale(1);
            transform: rotate(147deg) translate3d(596px, 630px, 1000px) scale(1);
  }
}

@keyframes anim191 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(147deg) translate3d(596px, 630px, 1000px) scale(1);
            transform: rotate(147deg) translate3d(596px, 630px, 1000px) scale(1);
  }
}

.tri:nth-child(192) {
  border-top: 38px solid #ff8000;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(354deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(354deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim192 10s infinite linear;
          animation: anim192 10s infinite linear;
  -webkit-animation-delay: -9.6s;
          animation-delay: -9.6s;
  opacity: 0;
}

@-webkit-keyframes anim192 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(531deg) translate3d(1px, 6px, 1000px) scale(1);
            transform: rotate(531deg) translate3d(1px, 6px, 1000px) scale(1);
  }
}

@keyframes anim192 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(531deg) translate3d(1px, 6px, 1000px) scale(1);
            transform: rotate(531deg) translate3d(1px, 6px, 1000px) scale(1);
  }
}

.tri:nth-child(193) {
  border-top: 7px solid #ff0009;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  margin-left: -3.5px;
  margin-top: -3.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(230deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(230deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim193 10s infinite linear;
          animation: anim193 10s infinite linear;
  -webkit-animation-delay: -9.65s;
          animation-delay: -9.65s;
  opacity: 0;
}

@-webkit-keyframes anim193 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(345deg) translate3d(428px, 155px, 1000px) scale(1);
            transform: rotate(345deg) translate3d(428px, 155px, 1000px) scale(1);
  }
}

@keyframes anim193 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(345deg) translate3d(428px, 155px, 1000px) scale(1);
            transform: rotate(345deg) translate3d(428px, 155px, 1000px) scale(1);
  }
}

.tri:nth-child(194) {
  border-top: 6px solid magenta;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left: -3px;
  margin-top: -3px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(285deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(285deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim194 10s infinite linear;
          animation: anim194 10s infinite linear;
  -webkit-animation-delay: -9.7s;
          animation-delay: -9.7s;
  opacity: 0;
}

@-webkit-keyframes anim194 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(427.5deg) translate3d(963px, 829px, 1000px) scale(1);
            transform: rotate(427.5deg) translate3d(963px, 829px, 1000px) scale(1);
  }
}

@keyframes anim194 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(427.5deg) translate3d(963px, 829px, 1000px) scale(1);
            transform: rotate(427.5deg) translate3d(963px, 829px, 1000px) scale(1);
  }
}

.tri:nth-child(195) {
  border-top: 38px solid #ff0011;
  border-right: 38px solid transparent;
  border-left: 38px solid transparent;
  margin-left: -19px;
  margin-top: -19px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(253deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(253deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim195 10s infinite linear;
          animation: anim195 10s infinite linear;
  -webkit-animation-delay: -9.75s;
          animation-delay: -9.75s;
  opacity: 0;
}

@-webkit-keyframes anim195 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(379.5deg) translate3d(425px, 424px, 1000px) scale(1);
            transform: rotate(379.5deg) translate3d(425px, 424px, 1000px) scale(1);
  }
}

@keyframes anim195 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(379.5deg) translate3d(425px, 424px, 1000px) scale(1);
            transform: rotate(379.5deg) translate3d(425px, 424px, 1000px) scale(1);
  }
}

.tri:nth-child(196) {
  border-top: 19px solid #ff0022;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  margin-left: -9.5px;
  margin-top: -9.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(281deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(281deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim196 10s infinite linear;
          animation: anim196 10s infinite linear;
  -webkit-animation-delay: -9.8s;
          animation-delay: -9.8s;
  opacity: 0;
}

@-webkit-keyframes anim196 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(421.5deg) translate3d(435px, 22px, 1000px) scale(1);
            transform: rotate(421.5deg) translate3d(435px, 22px, 1000px) scale(1);
  }
}

@keyframes anim196 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(421.5deg) translate3d(435px, 22px, 1000px) scale(1);
            transform: rotate(421.5deg) translate3d(435px, 22px, 1000px) scale(1);
  }
}

.tri:nth-child(197) {
  border-top: 46px solid #11ff00;
  border-right: 46px solid transparent;
  border-left: 46px solid transparent;
  margin-left: -23px;
  margin-top: -23px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(172deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(172deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim197 10s infinite linear;
          animation: anim197 10s infinite linear;
  -webkit-animation-delay: -9.85s;
          animation-delay: -9.85s;
  opacity: 0;
}

@-webkit-keyframes anim197 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(258deg) translate3d(874px, 412px, 1000px) scale(1);
            transform: rotate(258deg) translate3d(874px, 412px, 1000px) scale(1);
  }
}

@keyframes anim197 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(258deg) translate3d(874px, 412px, 1000px) scale(1);
            transform: rotate(258deg) translate3d(874px, 412px, 1000px) scale(1);
  }
}

.tri:nth-child(198) {
  border-top: 44px solid #ff0088;
  border-right: 44px solid transparent;
  border-left: 44px solid transparent;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(11deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(11deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim198 10s infinite linear;
          animation: anim198 10s infinite linear;
  -webkit-animation-delay: -9.9s;
          animation-delay: -9.9s;
  opacity: 0;
}

@-webkit-keyframes anim198 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(16.5deg) translate3d(300px, 581px, 1000px) scale(1);
            transform: rotate(16.5deg) translate3d(300px, 581px, 1000px) scale(1);
  }
}

@keyframes anim198 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(16.5deg) translate3d(300px, 581px, 1000px) scale(1);
            transform: rotate(16.5deg) translate3d(300px, 581px, 1000px) scale(1);
  }
}

.tri:nth-child(199) {
  border-top: 28px solid #00ffbf;
  border-right: 28px solid transparent;
  border-left: 28px solid transparent;
  margin-left: -14px;
  margin-top: -14px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(276deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(276deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim199 10s infinite linear;
          animation: anim199 10s infinite linear;
  -webkit-animation-delay: -9.95s;
          animation-delay: -9.95s;
  opacity: 0;
}

@-webkit-keyframes anim199 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(414deg) translate3d(496px, 645px, 1000px) scale(1);
            transform: rotate(414deg) translate3d(496px, 645px, 1000px) scale(1);
  }
}

@keyframes anim199 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(414deg) translate3d(496px, 645px, 1000px) scale(1);
            transform: rotate(414deg) translate3d(496px, 645px, 1000px) scale(1);
  }
}

.tri:nth-child(200) {
  border-top: 11px solid #00fff2;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  margin-left: -5.5px;
  margin-top: -5.5px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transform: rotate(130deg) translate3d(0, 0, -1500px) scale(0);
          transform: rotate(130deg) translate3d(0, 0, -1500px) scale(0);
  -webkit-animation: anim200 10s infinite linear;
          animation: anim200 10s infinite linear;
  -webkit-animation-delay: -10s;
          animation-delay: -10s;
  opacity: 0;
}

@-webkit-keyframes anim200 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(195deg) translate3d(655px, 145px, 1000px) scale(1);
            transform: rotate(195deg) translate3d(655px, 145px, 1000px) scale(1);
  }
}

@keyframes anim200 {
  0% {
    opacity: 1;
    -webkit-transform: rotate(195deg) translate3d(655px, 145px, 1000px) scale(1);
            transform: rotate(195deg) translate3d(655px, 145px, 1000px) scale(1);
  }
}
