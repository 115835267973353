/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');



/************ Default Css ***********/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --cursor-color: #fff;
  
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  overflow-x: hidden;
}



body {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(103deg, #141414 20%,#141414 46%, #000000 100%);
    overflow-x: hidden;
    position: relative;
    font-family: 'Centra', 'Poppins', sans-serif !important;
  }
/* ************************************ banner start ****************************** */
.banner {
  position: relative;
  margin-top: -45em;
  margin-left:10em;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bannertitile{
   /* font-family: 'Poppins', sans-serif; */
   font-size: 5em;
   background: linear-gradient(to right, #ffffff 35%, #ffffff 45%, #9f587b 60%, #711E48 100%);
   background-clip: text;
   -webkit-background-clip: text; 
   color: transparent;
   letter-spacing: 0.01em;
   font-weight: 900;
 }

.banner h6 {
   font-family: 'Poppins';
   color: #B8B8B8;
   font-size: 18px;
   letter-spacing: 0.8px;
   line-height: 1.7em;
   width: 89%;
   margin-top: 50px;
   
 }
 
 .banner img {
   animation: updown 10s linear infinite;
   width: 200%;
   max-width: 50em; 
   margin-left: 30%; 
   margin-top: 0%;
   position: relative;
   right: 50%;
   margin-top: -5em;
   
 }
 

.but {
   font-family: 'Poppins';
   all: unset;
   width: 120px;
   height: 25px;
   padding: 0.7em 2em;
   font-size: 16px;
   position: relative;
   color: #f0f0f0;
   cursor: pointer;
   z-index: 1;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 0.1em;
   border: solid 0.01em;
   border-image: linear-gradient(to right, #711E48, #ffffff) 1;
   transition: border 0.4s ease; 
 }
 .butnext {
  font-family: 'Poppins';
  all: unset;
  width: 120px;
  height: 25px;
  padding: 0.7em 2em;
  font-size: 16px;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1em;
  border: solid 0.01em;
  border-image: linear-gradient(to right, #711E48, #ffffff) 1;
  transition: border 0.4s ease; 
}
 .promobut {
  font-family: 'Poppins';
  background-color: #711E48;
  all: unset;
  width: 120px;
  height: 25px;
  font-size: 16px;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1em;
  border: solid 0.01em;
  border-image: linear-gradient(to right, #711E48, #ffffff) 1;
  transition: border 0.4s ease; 
}
 
 .but:hover {
   border-image: none; 
   border-color: transparent; 
 }
 
 .but::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 0.1em; 
   z-index: -1;
   background: linear-gradient(103deg, rgb(180, 178, 178) 0%, #711E48 100%);
   opacity: 0; 
   transition: opacity 0.4s ease; 
 }
 
 .but:hover::before {
   opacity: 1; 
 }
 .butnext:hover {
  border-image: none; 
  border-color: transparent; 
}

.butnext::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.1em; 
  z-index: -1;
  background: linear-gradient(103deg, rgb(180, 178, 178) 0%, #711E48 100%);
  opacity: 0; 
  transition: opacity 0.4s ease; 
}

.butnext:hover::before {
  opacity: 1; 
}

 @keyframes updown {
   0%, 100% {
     transform: translateY(-5%);
   }
   50% {
     transform: translateY(5%);
   }
 }

 
/* ************************************ banner end ****************************** */
/* ************************************ project start ****************************** */
.project {
   padding: 80px 0;
   position: relative;
  
 }
 .project h2 {
   font-family: 'Poppins';
   font-size: 36px;
   background: linear-gradient(to right, #ffffff 35%, #ffffff 45%, #9f587b 60%, #711E48 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   max-width: fit-content; 
   margin-bottom: 4%;
 }

 
 .proj-imgbx {
   position: relative;
   overflow: hidden;
   margin-bottom: 24px; 
 }
 .proj-imgbx::before {
   content: "";
   opacity: 0.85;
   position: absolute;
   width: 100%;
   height: 0;
   transition: 0.4s ease-in-out;
 }
 .proj-imgbx:hover::before {
   height: 100%;
   background-color: #010101ce;
 }
 .proj-txtx {
   position: absolute;
   text-align: center;
   top: 65%;
   left: 50%;
   transform: translate(-50%, -50%);
   transition: 0.5s ease-in-out;
   opacity: 0;
   width: 100%;
   color: white;
 }
 .proj-imgbx:hover .proj-txtx {
   top: 50%;
   opacity: 1;
 }
 .proj-txtx h4 {
   font-size: 30px;
   font-weight: 700;
   letter-spacing: 0.8px;
   line-height: 1.1em;
 }


/* ************************************ project end ****************************** */

/* ************************************ contact start ****************************** */
.contact h2 {
   font-family: 'Poppins';
   font-size: 36px;
   background: linear-gradient(to right, #ffffff 35%, #ffffff 45%, #9f587b 60%, #711E48 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   max-width: fit-content; 
   margin-bottom: 4%;
 }
/* ************************************ contact end ****************************** */
.footer {
   padding: 0 0 50px 0;
 }

 /* ************************************ Home end ****************************** */

 /* *************************** shop start ******************** */
 .shop h3 {
  font-family: 'Poppins';
  font-size: 40px;
  background: linear-gradient(to right, #ffffff 35%, #ffffff 45%, #9f587b 60%, #e078ac 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  max-width: fit-content;
  max-height: fit-content;  
  
 
}
.shop h2 {
  font-family: 'Poppins';
  font-size: 20px;
  color: #8b95a1;
}
.shop p {
  font-family: 'Poppins';
  font-size: 19px;
  color: #8b95a1;
}

 /* *************************** shop end ******************** */
/* ********************* job***************************** */
.register-affiliate {
  font-family: 'Poppins';
   font-size: 36px;
   color: white;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   max-width: fit-content; 
   margin-bottom: 4%;
}

.job h2{
  font-family: 'Poppins';
   font-size: 36px;
   background: linear-gradient(to right, #ffffff 35%, #ffffff 45%, #9f587b 60%, #e078ac 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   margin-top: 2em;
   max-width: fit-content; 

}
/************ About Css ************/
.about{
  margin-top: 150px; 
 }
 
 .clientstitle{
   font-family: 'Poppins';
   font-size: 40px;
   background: linear-gradient(to right, #ffffff 35%, #ffffff 45%, #9f587b 60%, #e078ac 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   color: transparent;
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   max-width: fit-content; 
 }
 
 .clientscontainer{
   background-color: #711E48;
 
 }

 .aboutdescription {
  font-family: 'Poppins';
  color: #B8B8B8;
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.7em;
  width: 89%;
  margin-top: 50px;
  
}
/* Add this to your CSS file */
.testimonials-container {
  padding: 20px; 
}

.testimonials-navigation {
  width: 100%;
  max-width: 1200px; 
}

.testimonials-button {
  text-align: center;
  cursor: pointer;
}

.testimonials-card {
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.testimonials-image-container {
  flex-shrink: 0;
  margin-right: 16px; 
}

.testimonials-image {
  width: 150px; 
  height: 150px;
  object-fit: cover;
  border-radius: 8px; 
}

.testimonials-indicators {
  margin-top: 8px; 
}

.testimonials-indicator {
  height: 8px; 
  width: 8px; 
  border-radius: 50%; 
  transition: background-color 0.3s;
}


@media screen and (max-width: 1500px) {
  .bannertitile {
    font-size: 50px;
  }
  .banner {
    margin-top: -50em;
    margin-left:4em;}
 }

@media screen and (max-width: 1200px) {
 .bannertitile {
   font-size: 50px;
 }
 .banner {
   margin-top: -55em;
   margin-left:2em;}
}

@media screen and (max-width: 992px) {
 .bannertitile {
   font-size: 50px;
 }
}

@media screen and (max-width: 768px) {
 .banner {
   margin-left:1em;}
 .bannertitile {
   font-size: 50px;
 }

}

@media screen and (max-width: 576px) {
  .banner {
     margin-top: -55em;
     margin-left:0em;}
 .bannertitile {
   font-size: 36px;
   padding-left: 0.8em;
 }
 .banner h6 {
  width: 90%;
  margin-left: 1em;
}
.aboutdescription{
  width: 90%;
  margin-left: 1em;
}
  .banner img {
  width: 100%;
  margin-top: 1em;
  margin-left: 13em;
}
.but {
  margin-left: 5em;
  width: 120px;
   height: 25px;
}
.shop h3 {
 font-size: 25px;
}
.shop h2 {
  font-size: 16px;
 }
 .shop p {
  font-size: 16px;
  margin-left: -0.8em;
 }
 
 .testimonials-navigation {
  width: 100%;
  max-width: 1200px; 
  margin-left: -4em;
}

.testimonials-image {
  width: 100px; 
  height: 100px;
  margin-left: 6em;
 
}
.testimonials-card {
 width: 400px;
}
.testimonials-indicators {
  margin-left: -3em; 
}
}

@media screen and (max-width: 376px) {
  .testimonials-navigation {
    margin-left: -6.5em;
  }
  .testimonials-indicators {
    margin-left: -6em; 
  }
  .testimonials-image {
    width: 100px; 
    height: 100px;
    margin-left: 5em;
   
  }
  .but {
    margin-left: 3.5em;
    width: 120px;
     height: 25px;
  }
}
@media screen and (max-width: 320px) {
  
  .testimonials-navigation {
    margin-left: -7em;

  }
  .testimonials-card {
    width: 380px;
   }
   .testimonials-image {
    width: 100px; 
    height: 100px;
    margin-left: 4em;
   
  }
  .but {
    margin-left: 2em;
    width: 120px;
     height: 25px;
  }
  
}
/* General logo styling */
nav img {
  width: 150px; /* Default size for larger screens */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensures proper layout in flex or grid */
  margin: 0 auto; /* Centers the logo */
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
  nav img {
    width: 100px; /* Smaller size for mobile screens */
    margin: 0; /* Optional: tweak margins for better alignment */
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures the layout adapts for mobile */
  }
}
