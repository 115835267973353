@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.animate-rotate {
  animation: 10s linear 1s infinite rotations;
}

@keyframes rotations {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background-color: rgb(99 102 241 / 0.5);
  animation: animate 10s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 9s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 22s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 17s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 6s;
}

$color: rgb(224 231 255);
$bg: rgb(129 140 248);

.Button {
  height: 3rem;
  width: 10rem;
  display: inline-block;
  text-align: center;
  background: $color;
  color: $bg;
  border-radius: 1em;
  position: relative;
  text-decoration: none;
  z-index: 40;
  transition: 0.3s;

  &Dark {
    color: $color;
    background-color: $bg;
    &:after, &:before{
      background: $bg!important;
    }
  }

  &>* {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;
  }

  &:hover {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: scale(1.05);
  }
}

.Button:before,
.Button:after {
  width: 2rem;
  height: 2rem;
  position: absolute;
  content: "";
  background: $color;
  border-radius: 50%;
  transition: transform 0.3s ease;
  transform: scale(0);
  z-index: 20;
}

.Button:hover:before,
.Button:hover:after {
  transform: scale(1);
}

.Button:before {
  top: -30%;
  left: 0%;
}

.Button:after {
  bottom: -30%;
  right: 0%;
}

.Button:hover:before,
.Button:hover:after {
  transform: none;
}

@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  40% {
    opacity: 0.5;
  }

  65% {
    opacity: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}